export const gatewayInfo = {
  publicKey: {
    label: "Public key",
    tooltip: "The gateway's unique public key."
  },
  h3Index: {
    label: "H3-Index",
    tooltip: "A unique identifier representing the gateway's geographic location using H3 geospatial indexing."
  },
  lastSeen: {
    label: "Last seen",
    tooltip: "The last time the gateway was online and communicated with our servers. This should be updated every 10 minutes, longer updates indicates that the gateway has problems connecting to the Internet."
  },
  uptime: {
    label: "Uptime",
    tooltip: "How long the gateway has been running since its last restart."
  },
  firmware: {
    label: "Firmware",
    tooltip: "The current version of the gateway's firmware. Updates are automatically installed when available."
  },
  serial: {
    label: "Serial / Id",
    tooltip: "The unique serial number of your gateway."
  },
  name: {
    label: "Name",
    tooltip: "The display name of your gateway."
  },
  der_lastSeen: {
    tooltip: "The last time valid data was sent to our servers. Irregular updates indicates that the some problem in communication between the gateway and device(s) or the gateway is offline."
  }
} as const; 
