import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { useWallet } from "@solana/wallet-adapter-react";
import { createMessageStr, signSrcfulMessage } from "../../messaging/Message";
import { ActionButton } from "../ui/ActionButton";
import { setLocation } from "../../services/backend/SrcfulApiRequests";

// Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFjb2ItazI4OSIsImEiOiJjbHNrbnJubjQwNGtwMmtyMDd3cTBmem5nIn0.-D_8MJk7ENckqUh9PD_g2A";

interface GatewayLocationFormProps {
  serial: string;
  variant?: 'gateway' | 'default';
  onSuccess?: () => void;
}

export default function GatewayLocationForm({ 
  serial, 
  variant = 'default',
  onSuccess 
}: GatewayLocationFormProps) {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);
  const [zoom] = useState<number>(4);
  const [lngLat, setLngLat] = useState({ lng: 10.9, lat: 50.2 });
  const { publicKey, signMessage } = useWallet();

  const { refetch, isFetching } = useQuery({
    queryKey: ["signLocationChange", serial],
    queryFn: async () => {
      if (!publicKey || !signMessage) return;
      const toastId = toast.loading("Waiting for signature...");

      //CREATE MESSAGE
      const messageData = {
        wallet: publicKey.toString(),
        durationInSec: 60,
        messageData: {
          Latitude: lngLat.lat.toFixed(6),
          Longitude: lngLat.lng.toFixed(6),
          "Gateway Serial": serial,
        },
        Statement:
          "By signing this message, I confirm that the location of the gateway is correct.",
      };
      
      const message = createMessageStr(messageData);

      //SIGN MESSAGE
      const signedMessage = await signSrcfulMessage(signMessage, message);

      if (!signedMessage) {
        toast.remove(toastId);
        return;
      }

      toast.loading("Sending data ...", {
        id: toastId,
      });

      try {
        const response = await setLocation(signedMessage.message, signedMessage.signature);
  
        onSuccess?.();
        toast.dismiss(toastId);
        toast.success("Successfully saved location");
        return response;
      } catch (error) {
        toast.dismiss(toastId);
        toast.error("Failed to update location");
        throw error;
      }
    },
    enabled: false,
    staleTime: Infinity,
    cacheTime: 0,
    retry: false
  });

  // Initialize map only once
  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/dark-v11",
        center: [lngLat.lng, lngLat.lat],
        zoom: zoom,
        minZoom: 2.7,
      });

      // Create marker element
      const el = document.createElement('div');
      el.innerHTML = `
      <svg height="40px" width="40px" viewBox="0 0 293.334 293.334" xml:space="preserve" fill="#4ADE80"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path style="fill:#4ADE80;" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z"></path> <circle style="fill:#4ADE80;" cx="146.667" cy="90.196" r="21.756"></circle> </g> </g> </g></svg>
      `;
      el.style.cursor = 'pointer';

      // Create and store marker
      marker.current = new mapboxgl.Marker(el, { offset: [0, -20] })
        .setLngLat([lngLat.lng, lngLat.lat])
        .addTo(map.current);

      // Add move handler
      map.current.on('move', () => {
        if (!map.current) return;
        const center = map.current.getCenter();
        setLngLat({
          lng: center.lng,
          lat: center.lat
        });
        if (marker.current) {
          marker.current.setLngLat([center.lng, center.lat]);
        }
      });

      // Force a resize after initialization to ensure proper sizing
      setTimeout(() => {
        map.current?.resize();
      }, 0);

      // Add resize handler
      const resizeHandler = () => {
        if (map.current) {
          map.current.resize();
        }
      };

      window.addEventListener('resize', resizeHandler);

      return () => {
        window.removeEventListener('resize', resizeHandler);
        if (marker.current) {
          marker.current.remove();
          marker.current = null;
        }
        if (map.current) {
          map.current.remove();
          map.current = null;
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array - we only want to initialize once with initial values

  return (
    variant === 'gateway' ? (
      // Gateway variant - full height with contained button
      <div className="h-full flex flex-col">
        <div className="flex-grow min-h-0">
          <div className="h-full w-full relative bg-glass rounded-lg overflow-hidden">
            <div ref={mapContainer} className="absolute inset-0 w-full h-full">
            </div>
            <div className="absolute top-4 left-4 bg-glass text-gray-200 p-3 rounded-lg shadow-lg z-10">
              <p>Longitude: {lngLat.lng.toFixed(6)}</p>
              <p>Latitude: {lngLat.lat.toFixed(6)}</p>
            </div>
          </div>
        </div>
        <div className="mt-4 flex-shrink-0">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              refetch();
            }}
            className="flex justify-end"
          >
            <ActionButton
              type="submit"
              isLoading={isFetching}
              loadingText="Processing"
              disabled={isFetching}
            >
              Assert Location
            </ActionButton>
          </form>
        </div>
      </div>
    ) : (
      // Default variant - fixed height for mobile/wizard
      <div className="mt-4 flex flex-col w-full gap-4">
        <div className="bg-glass h-[300px] w-full overflow-hidden rounded-lg">
          <div ref={mapContainer} className="h-full w-full">
            <div className="absolute top-4 left-4 bg-glass text-gray-200 p-3 rounded-lg shadow-lg z-10">
              <p>Longitude: {lngLat.lng.toFixed(6)}</p>
              <p>Latitude: {lngLat.lat.toFixed(6)}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              refetch();
            }}
          >
            <ActionButton
              type="submit"
              isLoading={isFetching}
              loadingText="Processing"
              disabled={isFetching}
            >
              Assert Location
            </ActionButton>
          </form>
        </div>
      </div>
    )
  );
} 