import { createContext, useContext, FC, ReactNode, useEffect, useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { router } from '../router';

interface WalletContextType {
  isConnected: boolean;
  isConnecting: boolean;
  resetConnection: () => void;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { connected, connecting, disconnect } = useWallet();
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (connecting) {
      setIsConnecting(true);
    } else if (!connecting && !connected) {
      // If we stopped connecting but aren't connected, reset after a delay
      const timer = setTimeout(() => {
        setIsConnecting(false);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (connected) {
      setIsConnecting(false);
    }
  }, [connecting, connected]);

  const resetConnection = () => {
    setIsConnecting(false);
    disconnect();
  };

  useEffect(() => {
    if (!connected && !connecting && window.location.pathname !== '/login') {
      router.navigate({ to: '/login', replace: true });
    }
  }, [connected, connecting]);

  return (
    <div data-wallet-context data-is-connected={connected}>
      <WalletContext.Provider value={{ 
        isConnected: connected,
        isConnecting,
        resetConnection
      }}>
        {children}
      </WalletContext.Provider>
    </div>
  );
};

export const useWalletContext = () => {
  const context = useContext(WalletContext);
  if (context === undefined) {
    throw new Error('useWalletContext must be used within a WalletContextProvider');
  }
  return context;
}; 