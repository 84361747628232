import { IManager } from './Manager.interface';
import { Method } from "./methods";
import { EGWTPResponse } from "./EGWTPResponse";



export class Api {
  
  private observers: ((response: EGWTPResponse) => void)[] = [];
  constructor(public manager: IManager, private timeout = 30000, private retries = 3) {}


  subscribe(callback: (response: EGWTPResponse) => void) : void {
    if (!this.observers.includes(callback)) {
      this.observers.push(callback);
    }
  }

  unsubscribe(callback: (response: EGWTPResponse) => void) : void {
    this.observers = this.observers.filter(
      (observer) => observer !== callback,
    );
  }
    
  fetch(
    endpoint: string, 
    method: Method, 
    payload: Record<string, any> = {},
    offset = 0,
    options?: {
      timeout?: number;
      retries?: number;
    }
  ): Promise<EGWTPResponse> {
    let attempts = 0;
    let timeoutId: any;
    let requestedData: (number|undefined)[] = [];
    
    // Use provided options or fall back to default values
    const timeoutValue = options?.timeout ?? this.timeout;
    const retriesValue = options?.retries ?? this.retries;
        
    return new Promise((resolve, reject) => {
      const observer = (response: EGWTPResponse) => {
        if (response.location !== endpoint) return;
        clearTimeout(timeoutId);

        if (requestedData.length < response.contentLength) {
            requestedData = new Array(response.contentLength);
            requestedData.fill(undefined);
        }

        for (let i = 0; i < response.content.length; i++) {
            requestedData[response.offset + i] = response.content[i];
        }

        // find the first point were we have undefined data
        offset = requestedData.indexOf(undefined);
            
        if (offset >= 0) {
            console.log("Requesting more data from offset: ", offset);
            attemptRequest();
        } else {
            const result = response.cloneWithPayloadFromBytes(requestedData as number[]);
            this.manager.unsubscribe(observer);

            for (const observerCallback of this.observers) {
                observerCallback(result);
            }
            resolve(result);
        }
      };
          
      const attemptRequest = () => {
        attempts++;
        this.manager.send(endpoint, method, payload, offset);
        timeoutId = setTimeout(() => {
          if (attempts >= retriesValue) {
            this.manager.unsubscribe(observer);
            reject(new Error(`Request to ${endpoint} timed out after ${retriesValue} attempts`));
            return;
          } else if (!this.manager.isConnected()) {
            this.manager.unsubscribe(observer);
            reject(new Error(`Request to ${endpoint} failed because the device is not connected`));
            return;
          }

          attemptRequest();
        }, timeoutValue);
      };

      this.manager.subscribe(observer);
      attemptRequest();
    });
  }
}
    