import { FC } from "react";
import { useNavigation } from "../context/NavigationContext";
import { SourcfulEnergyLogo } from "./icons/brand_icons";

const Header: FC = () => {
  const { navOpen, toggleNav } = useNavigation();

  const handleMenuClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    toggleNav();
  };

  return (
    <header className="z-50">
      <nav className="bg-[#242526] px-4 py-2 lg:p-4 lg:px-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-1">
            <a className="flex items-center text-gray-50" href="/">
              <SourcfulEnergyLogo className="h-8 lg:h-10" />
            </a>
          </div>
          <button 
            className="lg:hidden relative w-[30px] h-[30px] focus:outline-none"
            onMouseDown={handleMenuClick}
          >
            <div className={`absolute w-[30px] h-[3px] bg-white transition-all duration-300 ${
              navOpen ? 'rotate-45 top-[13.5px]' : 'rotate-0 top-[5px]'
            }`} />
            <div className={`absolute w-[30px] h-[3px] bg-white transition-all duration-300 ${
              navOpen ? 'opacity-0 top-[13.5px]' : 'opacity-100 top-[13.5px]'
            }`} />
            <div className={`absolute w-[30px] h-[3px] bg-white transition-all duration-300 ${
              navOpen ? '-rotate-45 top-[13.5px]' : 'rotate-0 top-[22px]'
            }`} />
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
