import { SignedMessage } from "../../messaging/Message";

import { encode as encodeBase58 } from 'bs58';



const apiFetch = async (query: string) => {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Content-Length", query.length.toString());

    const response = await fetch("https://api.srcful.dev/", {
        method: "POST",
        body: query,
        headers: requestHeaders,
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, details: ${errorText}`);
    }

    return await response.json();
}

export const fetchGatewayState = async (gateway_id: string, signedMessage: SignedMessage): Promise<Record<string, any>> => {
    const query = JSON.stringify({
      query: `
        query get_config_wallet {
          gatewayConfiguration {
            configuration(walletAuth: {
              message: "${signedMessage.message}"
              signature: "${signedMessage.signature}"
              id: "${gateway_id}"
              subKey: "state"
            }) {
              data
            }
          }
        }
      `
    });


   try {
    const response = await apiFetch(query);
    return JSON.parse(response.data.gatewayConfiguration.configuration.data);
   } catch (error) {
    console.error('Error fetching gateway state:', error);
    throw new Error('Failed to fetch gateway state');
   }
};

// encode and sign a message with a given sign function return base58 encoded message and signature
async function signMessage_util(message: string, sign_function: (message: Uint8Array) => Promise<Uint8Array>) {
  const messageBytes = new TextEncoder().encode(message);
  const signatureBytes = await sign_function(messageBytes);
  return {
    message: encodeBase58(messageBytes),
    signature: encodeBase58(signatureBytes)
  };
}

export async function gwRequest(message: string, sign_function: (message: Uint8Array) => Promise<Uint8Array>) {
  const { message: messageBase58, signature } = await signMessage_util(message, sign_function);
  return setConfiguration_(messageBase58, signature);
}

const setConfiguration_ = async (message: string, signature: string, subKey: string = "request") => {
    const query = JSON.stringify({
        query: `
            mutation {          
                setConfiguration(messageConfigurationInputType: {   
                    message: "${message}",           
                    signature: "${signature}"        
                }) {
                    success
                }
            }
        `
    });

    console.log(query);

    try {
            const response = await apiFetch(query);
            return response.data.setConfiguration.success;
    } catch (error) {
        console.error('Error setting configuration:', error);
        throw new Error('Failed to set configuration');
    }
  }

export const setLocation = async (message: string, signature: string) => {
   //UPDATE
   const mutation = {
    query: `
      mutation($location: LocationInput!) {
        setLocation(location: $location){
          success
        }
      }`,
    variables: {
      location: {
        message: message,
        signature: signature,
      },
    },
  };

  const query = JSON.stringify(mutation);
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Content-Length", query.length.toString());


  try {
    const response = await fetch("https://api.srcful.dev/", {
      method: "POST",
      body: query,
      headers: requestHeaders,
    });
    const responseData = await response.json();

    if (!response.ok) {
      throw new Error('API request failed');
    }

    if (!responseData.data?.setLocation?.success) {
      throw new Error('Location update was not successful');
    }

    return responseData.data.setLocation.success;
  } catch (error) {
    console.error('Error setting location:', error);
    throw error;
  }
}