interface MessageData {
  [key: string]: string | number | object;
}

interface MessageOptions {
  walletPublicKey: string;
  statement: string;
  messageData?: MessageData;
  expirationMinutes?: number;
}

interface BaseDeviceConfig {
  ip: string;
  port: number;
  connection: string;
}

interface InverterConfig extends BaseDeviceConfig {
  device_type: string;
  slave_id: number;
}

// interface SolarmanInverterConfig extends InverterConfig {
//   device_type: 'SOLARMAN';
//   sn: number;   // solarman dongle serial number
// }

interface P1MeterConfig extends BaseDeviceConfig {
  // nothing extra here is needed
}

class SrcfulMessageProtocolFactory {
  private static readonly BASE_URL = 'https://srcful.io/';

  /**
   * Creates a message following the Srcful Message Protocol format and encodes it in base58
   */
  static createMessage({
    walletPublicKey,
    statement,
    messageData = {},
    expirationMinutes = 9
  }: MessageOptions): string {
    const now = new Date();
    const expiration = new Date(now.getTime() + expirationMinutes * 60 * 1000);

    let message = `${this.BASE_URL} wants you to sign data with your Solana account:\n`;
    message += `${walletPublicKey}\n\n`;
    message += `${statement}\n\n`;

    // Add optional message data if provided
    if (Object.keys(messageData).length > 0) {
      for (const [key, value] of Object.entries(messageData)) {
        message += `${key}: ${typeof value === 'object' ? JSON.stringify(value) : value}\n`;
      }
    }

    message += `Issued At (UTC): ${now.toISOString()}\n`;
    message += `Expiration Time (UTC): ${expiration.toISOString()}`;

    return message;
  }

  /**
   * Creates a base58 encoded message for gateway configuration
   */
  static createGatewayConfigMessage({
    walletPublicKey,
    gatewayId,
    configData,
    subKey = 'request'
  }: {
    walletPublicKey: string;
    gatewayId: string;
    configData: object;
    subKey?: string;
  }): string {
    return this.createMessage({
      walletPublicKey,
      statement: 'Gateway configuration update',
      messageData: {
        'SubKey': subKey,
        'Configuration': configData,
        'Gateway Serial': gatewayId
      }
    });
  }

  /**
   * Creates a base58 encoded message for claiming gateway ownership
   */
  static createClaimOwnershipMessage({
    walletPublicKey,
    gatewayIds,
    expirationDays = 365
  }: {
    walletPublicKey: string;
    gatewayIds: string[];
    expirationDays?: number;
  }): string {
    const gatewayCount = gatewayIds.length;
    return this.createMessage({
      walletPublicKey,
      statement: `Add ${gatewayCount} gateway${gatewayCount === 1 ? '' : 's'} to the application`,
      messageData: {
        'Gateways': gatewayIds.join(',')
      },
      expirationMinutes: expirationDays * 24 * 60
    });
  }

  /**
   * Creates a base58 encoded message for inverter configuration
   */
  static createDeviceConfigMessage({
    walletPublicKey,
    gatewayId,
    deviceConfig,
    deviceDisplayName
  }: {
    walletPublicKey: string;
    gatewayId: string;
    deviceConfig: InverterConfig | P1MeterConfig;
    deviceDisplayName: string;
  }): string {
    const requestData = {
      'method': 'POST',
      'path': '/api/device',
      'headers': "{}",
      'body': deviceConfig,
      'query': "{}",
      'timestamp': Date.now(),
      'id': "some-id"
    };

    const statement = `Changing configuration for device ${deviceDisplayName} with IP: ${deviceConfig.ip}, Port: ${deviceConfig.port}`;

    console.log(JSON.stringify(requestData));

    return this.createMessage({
      walletPublicKey,
      statement,
      messageData: {
        'SubKey': 'request',
        'Configuration': requestData,
        'Gateway Serial': gatewayId
      }
    });
  }

  static deleteDeviceConfigMessage({
    walletPublicKey,
    gatewayId,
    deviceId,
    deviceDisplayName
  }: {
    walletPublicKey: string;
    gatewayId: string;
    deviceId: string;
    deviceDisplayName: string;
  }): string {

    const deviceConfig = {
      'id': deviceId
    };

    const requestData = {
      'method': 'DELETE',
      'path': '/api/device',
      'headers': "{}",
      'body': deviceConfig,
      'query': "{}",
      'timestamp': Date.now(),
      'id': "some-id"
    };
  
    const statement = `Removing device ${deviceDisplayName} with ID: ${deviceId}`;
  
    console.log(JSON.stringify(requestData));
  
    return this.createMessage({
      walletPublicKey,
      statement,
      messageData: {
        'SubKey': 'request',
        'Configuration': requestData,
        'Gateway Serial': gatewayId
      }
    });
  }

  static scanDevicesMessage({
    walletPublicKey,
    gatewayId,
  }: {
    walletPublicKey: string;
    gatewayId: string;
  }): string {
    const requestData = {
      'method': 'GET',
      'path': '/api/device/scan',
      'headers': "{}",
      'body': {},
      'query': "{}",
      'timestamp': Date.now(),
      'id': "some-id"
    };

    const statement = "Scan for available devices";

    return this.createMessage({
      walletPublicKey,
      statement,
      messageData: {
        'SubKey': 'request',
        'Configuration': requestData,
        'Gateway Serial': gatewayId
      }
    });
  }

  /**
   * Creates a base58 encoded message for WiFi configuration
   */
  static createWifiConfigMessage({
    walletPublicKey,
    gatewayId,
    ssid,
    psk
  }: {
    walletPublicKey: string;
    gatewayId: string;
    ssid: string;
    psk: string;
  }): string {
    const requestData = {
      'method': 'POST',
      'path': '/api/wifi',
      'headers': "{}",
      'body': {
        ssid,
        psk
      },
      'query': "{}",
      'timestamp': Date.now(),
      'id': "some-id"
    };

    const statement = `Configure WiFi for gateway ${gatewayId} with SSID: ${ssid}`;

    return this.createMessage({
      walletPublicKey,
      statement,
      messageData: {
        'SubKey': 'request',
        'Configuration': requestData,
        'Gateway Serial': gatewayId
      }
    });
  }

  static createWifiScanMessage({
    walletPublicKey,
    gatewayId,
  }: {
    walletPublicKey: string;
    gatewayId: string;
  }): string {
    const requestData = {
      'method': 'GET',
      'path': '/api/wifi/scan',
      'headers': "{}",
      'body': {},
      'query': "{}",
      'timestamp': Date.now(),
      'id': "some-id"
    };

    const statement = "Scan for available WiFi networks";

    return this.createMessage({
      walletPublicKey,
      statement,
      messageData: {
        'SubKey': 'request',
        'Configuration': requestData,
        'Gateway Serial': gatewayId
      }
    });
  }

  static deleteNotificationMessage({
    walletPublicKey,
    gatewayId,
    notificationId,
  }: {
    walletPublicKey: string;
    gatewayId: string;
    notificationId: number;
  }): string {
    const requestData = {
      'method': 'DELETE',
      'path': `/api/notification/${notificationId}`,
      'headers': "{}",
      'body': {},
      'query': "{}",
      'timestamp': Date.now(),
      'id': "some-id"
    };

    const statement = `Delete notification with ID: ${notificationId}`;

    return this.createMessage({
      walletPublicKey,
      statement,
      messageData: {
        'SubKey': 'request',
        'Configuration': requestData,
        'Gateway Serial': gatewayId
      }
    });
  }
}




export default SrcfulMessageProtocolFactory; 