import { FC, useEffect, useState } from 'react';
import GatewayLocationForm from "../form/GatewayLocationForm";

interface LocationStepProps {
  gatewayId: string;
  onLocationSet: () => void;
}

const LocationStep: FC<LocationStepProps> = ({ gatewayId, onLocationSet }) => {
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkLocation = async () => {
      try {
        const query = JSON.stringify({
          query: `{
            gateway {
              gateway(id: "${gatewayId}") {
                h3Index
              }
            }
          }`
        });

        const response = await fetch('https://api.srcful.dev/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: query
        });

        const data = await response.json();
        if (data.data?.gateway?.gateway?.h3Index) {
          onLocationSet();
        }
      } catch (error) {
        console.error('Failed to check gateway location:', error);
      } finally {
        setIsChecking(false);
      }
    };

    checkLocation();
  }, [gatewayId, onLocationSet]);

  if (isChecking) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Set Gateway Location</h2>
        <p className="text-gray-300">
          Checking current location status...
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Set Gateway Location</h2>
      <p className="text-gray-300">
              Please provide the precise location of the gateway. Inaccurate location may reduce your rewards. The exact location not shared publically.
      </p>
      <GatewayLocationForm 
        serial={gatewayId} 
        variant="default" 
        onSuccess={onLocationSet}
      />
    </div>
  );
};

export default LocationStep; 