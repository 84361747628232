import { Link } from "@tanstack/react-router";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
// import GatewayIcon from './icons/GatewayIcon';

interface GatewayPageHeaderProps {
  gatewayName: string;
  gatewayState?: 'active' | 'inactive' | 'disabled' | 'unknown' | 'none';
}

const GatewayPageHeader = ({ gatewayName, gatewayState = 'none' }: GatewayPageHeaderProps) => {
  return (
    <div className="mb-1">
      <div className="border-b border-gray-700/50 pb-2 mb-3">
        <div className="flex items-center gap-2">
          <Link 
            to="/"
            className="p-1 hover:bg-white/10 rounded-lg transition-colors"
          >
            <ArrowLeftIcon className="w-5 h-5 text-gray-400" />
          </Link>
          <div className="flex items-center gap-3">
            {/* <GatewayIcon state={gatewayState} className="w-6 h-6" /> */}
            <h2 className="text-lg font-medium text-gray-400">
              {gatewayName}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatewayPageHeader; 