import { FC, useState, useEffect } from 'react';
import { ActionButton } from '../../components/ui/ActionButton';
import gwBluetooth from '../../assets/gwschematics/gw-bluetooth.png';
import gwEthernet from '../../assets/gwschematics/gw-ethernet.png';
import gwPowerSupply from '../../assets/gwschematics/gw-power-supply.png';

interface BluetoothStepProps {
  onConnect: () => void;
  isConnecting: boolean;
  isConnected: boolean;
  hasConnectionFailed: boolean;
}

const BluetoothStep: FC<BluetoothStepProps> = ({ 
  onConnect,
  isConnecting,
  isConnected,
  hasConnectionFailed
}) => {
  const images = [gwPowerSupply, gwEthernet, gwBluetooth];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsTransitioning(false);
      }, 500); // Half of the transition duration
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(timer);
  }, [images.length]);

  return (
    <div className="space-y-0">
      <h2 className="text-2xl font-bold text-white">Enable Bluetooth</h2>

      <div className="relative h-64 w-full overflow-hidden">
        <img
          src={images[currentImageIndex]}
          alt={`Gateway setup step ${currentImageIndex + 1}`}
          className={`absolute inset-0 h-full w-full object-contain transition-opacity duration-1000 ${
            isTransitioning ? 'opacity-0' : 'opacity-100'
          }`}
        />
      </div>

      <div className="space-y-1text-gray-300">
        {/* <div className="bg-blue-500/10 p-4 rounded-lg border border-blue-500/20"> */}
          <ol className="list-decimal list-inside space-y-2 mt-2">
            <li>Enable Bluetooth on your device and browser (we recommend Chrome)</li>
            <li>Locate the button on your gateway and double-click it rapidly</li>
          </ol>
        {/* </div> */}
        
        {hasConnectionFailed && (
          <div className="space-y-4 mt-4">
            <div className="bg-yellow-500/10 p-4 rounded-lg border border-yellow-500/20">
              <h3 className="font-semibold mb-2 text-yellow-200">If Connection Failed:</h3>
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium mb-1">0. Try Again</h4>
                  <p className="text-sm">
                    - Simply try connecting again.
                    <br/>
                    - Try{" "}
                    <span 
                      className="text-blue-400 cursor-pointer hover:underline" 
                      onClick={() => window.location.reload()}
                    >
                      reloading the window
                    </span> and restart the wizard.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium mb-1">1. Wait for Initial Setup</h4>
                  <p className="text-sm">
                    - Power off you gateway and connect it to ethernet. Then power it on again.
                    <br/>
                    - Wait at least 10 minutes for firmware updates to install.
                  </p>
                </div>
                <div>
                  <h4 className="font-medium mb-1">2. Check Bluetooth Settings</h4>
                  <p className="text-sm">
                    - Disconnect other Bluetooth devices
                    <br/>
                    - Turn Bluetooth off and on again, alternatively restart your device
                    <br/>
                    - Make sure you're within 2 meters of the gateway
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        
        <div className="mt-6">
          <ActionButton
            onClick={onConnect}
            disabled={isConnected}
            isLoading={isConnecting}
            loadingText="Connecting to Gateway..."
            variant="primary"
            className="w-full py-3"
          >
            {isConnected ? 'Connected' : 'Connect to Gateway'}
          </ActionButton>
        </div>
      </div>
    </div>
  );
};

export default BluetoothStep; 