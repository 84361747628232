import React from 'react';
import { useGateways } from '../context/GatewayContext';
import { Button } from './ui/Button';
import { AuthLayout } from '../components/layout/AuthLayout';
import { UilCircuit } from '@iconscout/react-unicons';
import { useWallet } from '@solana/wallet-adapter-react';

export const GatewaySigningStep = () => {
  const { gateways, fetchGatewayStates, isLoading, suppressSigningStep } = useGateways();
  const { disconnect, publicKey } = useWallet();

  if (suppressSigningStep || gateways.length === 0) {
    return null;
  }

  return (
    <div className="h-screen bg-[#1d1d1d]">
      <AuthLayout>
        <div className="space-y-2">
          <h1 className="text-3xl font-bold text-white">
            Gateway Access Required
          </h1>
          <p className="text-center text-gray-300">
            Sign to access your {gateways.length} gateway{gateways.length > 1 ? 's' : ''}
          </p>
        </div>

        <div className="w-full space-y-2 rounded-lg bg-gray-800/50 p-4">
          {gateways.map((gateway) => (
            <div 
              key={gateway.id}
              className="flex items-center px-3 py-2 text-sm rounded-lg transition-colors"
            >
              <div className="flex items-center flex-1 min-w-0">
                <div className="flex items-center justify-center w-8 h-8 rounded bg-blue-600/20 text-blue-400">
                  <UilCircuit className="w-5 h-5" />
                </div>
                <div className="min-w-0 flex-1 ml-3">
                  <div className="truncate font-medium text-gray-200">
                    {gateway.name}
                  </div>
                  <div className="truncate text-gray-400 text-xs">
                    {gateway.id}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <Button
          onClick={() => fetchGatewayStates()}
          disabled={isLoading}
          className="w-full py-3 text-base font-medium bg-blue-600 hover:bg-blue-700 text-white rounded-md"
        >
          {isLoading ? (
            <div className="flex items-center justify-center space-x-2">
              <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                <circle 
                  className="opacity-25" 
                  cx="12" 
                  cy="12" 
                  r="10" 
                  stroke="currentColor" 
                  strokeWidth="4"
                  fill="none"
                />
                <path 
                  className="opacity-75" 
                  fill="currentColor" 
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
              <span>Signing...</span>
            </div>
          ) : (
            'Sign to Continue'
          )}
        </Button>

        <p className="text-center text-sm text-gray-400">
          This signature will grant you access to manage your gateways
        </p>

        <button
          onClick={disconnect}
          className="text-sm text-gray-400 hover:text-white transition-colors"
        >
          Disconnect Wallet
          <div className="truncate text-gray-400 text-xs">
            {publicKey?.toString()}
          </div>
        </button>
      </AuthLayout>
    </div>
  );
}; 