import { useSwipeable } from 'react-swipeable';
import { useRouter } from '@tanstack/react-router';
import { useGateways } from '../context/GatewayContext';
import { ReactNode, useState, useEffect, useRef, useMemo, useCallback } from 'react';

interface SwipeableContentProps {
  children: ReactNode;
  enterDirection: 'left' | 'right';
  onDirectionChange: (direction: 'left' | 'right') => void;
}

const SwipeableContent = ({ children, enterDirection, onDirectionChange }: SwipeableContentProps) => {
  const router = useRouter();
  const { activeGateway } = useGateways();
  const [pendingNavigation, setPendingNavigation] = useState<number | null>(null);
  const isAnimating = useRef(false);
  const isFirstMount = useRef(true);

  const routes = useMemo(() => [
    'overview',
    'notifications',
    'devices',
    'network',
    'location',
  ], []);

  const getCurrentRouteIndex = useCallback(() => {
    const currentPath = window.location.pathname;
    const currentRoute = routes.find(route => 
      currentPath === `/gateway/${activeGateway?.id}${route === 'overview' ? '' : `/${route}`}`
    );
    
    // If we're coming from the homepage cards, set the initial index based on the route
    if (!currentRoute && currentPath.includes(`/gateway/${activeGateway?.id}`)) {
      return 0; // Default to overview when coming from homepage
    }
    
    return currentRoute ? routes.indexOf(currentRoute) : -1;
  }, [routes, activeGateway?.id]);

  // Handle enter animation on mount
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      return;
    }

    const element = document.getElementById('page-content');
    if (element && !isAnimating.current) {
      
      // Set initial position
      element.style.transition = 'none';
      element.style.transform = `translateX(${enterDirection === 'right' ? '100%' : '-100%'})`;
      element.style.visibility = 'visible'; // Ensure content is visible
      
      // Force reflow
      void element.offsetWidth;
      
      // Animate to center
      requestAnimationFrame(() => {
        element.style.transition = 'transform 300ms ease-out';
        element.style.transform = 'translateX(0)';
      });
    }
  }, [router.state.location.pathname, enterDirection]); 

  // Handle exit animation and navigation
  useEffect(() => {
    if (pendingNavigation !== null && !isAnimating.current) {
      isAnimating.current = true;
      const route = routes[pendingNavigation];
      const path = route === 'overview' 
        ? `/gateway/${activeGateway?.id}`
        : `/gateway/${activeGateway?.id}/${route}`;
      
      const element = document.getElementById('page-content');
      if (element) {
        const currentIndex = getCurrentRouteIndex();
        
        // Special case the wrap-around scenarios
        const exitToRight = currentIndex === 0 && pendingNavigation === routes.length - 1
          ? true   // Going from first to last
          : currentIndex === routes.length - 1 && pendingNavigation === 0
          ? false  // Going from last to first
          : pendingNavigation < currentIndex;  // Normal case
        
        // Set direction for next page
        onDirectionChange(exitToRight ? 'left' : 'right');
        
        // Animate out
        element.style.transition = 'transform 300ms ease-out';
        element.style.transform = exitToRight ? 'translateX(100%)' : 'translateX(-100%)';

        // Wait for animation to complete before navigating
        setTimeout(() => {
          router.navigate({ to: path }).then(() => {
            setPendingNavigation(null);
            isAnimating.current = false;
            
            // Reset transform after navigation
            element.style.transform = 'translateX(0)';
          });
        }, 300);
      }
    }
  }, [pendingNavigation, activeGateway?.id, routes, router, onDirectionChange, getCurrentRouteIndex]);

  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      const target = e.event.target as HTMLElement;
      if (target.closest('.mapboxgl-map')) {
        return;
      }

      const currentIndex = getCurrentRouteIndex();
      if (!isAnimating.current) {
        // If we're at the last page, go to first page, otherwise go to next page
        setPendingNavigation(currentIndex === routes.length - 1 ? 0 : currentIndex + 1);
      }
    },
    onSwipedRight: (e) => {
      const target = e.event.target as HTMLElement;
      if (target.closest('.mapboxgl-map')) {
        return;
      }

      const currentIndex = getCurrentRouteIndex();
      if (!isAnimating.current) {
        // If we're at the first page, go to last page, otherwise go to previous page
        setPendingNavigation(currentIndex === 0 ? routes.length - 1 : currentIndex - 1);
      }
    },
    preventScrollOnSwipe: true,
    trackMouse: false
  });

  // Only enable swipe navigation on mobile devices
  if (window.innerWidth >= 1024) {
    return <>{children}</>;
  }

  return (
    <div 
      {...handlers} 
      className="relative w-full h-full min-h-screen overflow-hidden"
    >
      <div 
        id="page-content" 
        className="w-full h-full"
        style={{ 
          transform: 'translateX(0)',
          transition: 'none',
          visibility: 'visible'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SwipeableContent; 