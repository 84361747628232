import { FC, useState, useRef, useEffect } from 'react';
import ContentFramework from "../components/ContentFramework";
import GatewayPageHeader from '../components/GatewayPageHeader';
import PageContainer from '../components/layout/PageContainer';
import { ActionButton } from '../components/ui/ActionButton';
import { useRouter } from "@tanstack/react-router";
import { toast } from "react-hot-toast";
import SolarEdgeService from '../services/SolarEdgeService';
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { createSolarEdgeCloudGateway } from '../services/backend/SrcfulPublicApi';
import { useGateways } from '../context/GatewayContext';
import { useWallet } from '@solana/wallet-adapter-react';
import { SolarEdgeIcon, HuaweiIcon } from '../components/icons/brand_icons';
import InfoRow from '../components/InfoRow';

// Mapbox access token
mapboxgl.accessToken =
  "pk.eyJ1IjoiamFjb2ItazI4OSIsImEiOiJjbHNrbnJubjQwNGtwMmtyMDd3cTBmem5nIn0.-D_8MJk7ENckqUh9PD_g2A";

type Brand = 'solaredge' | 'huawei';

interface SolarEdgeCredentials {
  siteId: string;
  apiKey: string;
}

interface LocationData {
  latitude: string;
  longitude: string;
}

// Add new interface for Huawei session
interface HuaweiSession {
  session_id: string;
}

// Step Components
const IntroStep: FC<{ 
  onNext: () => void;
  selectedBrand: Brand | null;
  onSelectBrand: (brand: Brand) => void;
}> = ({ onNext, selectedBrand, onSelectBrand }) => (
  <div className="space-y-8">
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold">Onboard Your Cloud Gateway</h2>
      </div>
      <div className="space-y-4">
        <p className="text-gray-300">Select your inverter brand to begin:</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-w-xl mx-auto">
          <button
            onClick={() => onSelectBrand('solaredge')}
            className={`p-6 rounded-lg border-2 transition-all ${
              selectedBrand === 'solaredge'
                ? 'border-green-500 bg-green-500/10'
                : 'border-gray-700 hover:border-gray-600 bg-gray-800/50'
            }`}
          >
            <SolarEdgeIcon height={40} className="text-white mx-auto" />
          </button>
          <button
            onClick={() => onSelectBrand('huawei')}
            className={`p-6 rounded-lg border-2 transition-all ${
              selectedBrand === 'huawei'
                ? 'border-green-500 bg-green-500/10'
                : 'border-gray-700 hover:border-gray-600 bg-gray-800/50'
            }`}
          >
            <HuaweiIcon height={40} className="text-white mx-auto" />
          </button>
        </div>
      </div>
      <div className="space-y-1 text-gray-300">
        <p>Connect your site to the Sourceful Energy Network. Click 'Next' to begin the setup process.</p>
      </div>
    </div>

    <div className="flex justify-end">
      <ActionButton
        onClick={onNext}
        variant="primary"
        disabled={!selectedBrand}
      >
        Next
      </ActionButton>
    </div>
  </div>
);

const CredentialsStep: FC<{
  credentials: SolarEdgeCredentials;
  setCredentials: (creds: SolarEdgeCredentials) => void;
  onBack: () => void;
  onNext: () => void;
  isValidating: boolean;
  validationError: string | null;
  selectedBrand: Brand;
  location: LocationData;
  publicKey: string | null;
}> = ({ 
  credentials, 
  setCredentials, 
  onBack, 
  onNext, 
  isValidating, 
  validationError,
  selectedBrand,
  location,
  publicKey
}) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [isCreatingSession, setIsCreatingSession] = useState(false);

  const handleHuaweiAuth = async () => {
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsCreatingSession(true);
    try {
      const response = await fetch('https://huawei.srcful.dev/gateway_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ownerWallet: publicKey,
          longitude: parseFloat(location.longitude),
          latitude: parseFloat(location.latitude),
          redirect_url: 'https://app.srcful.io'
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create Huawei session');
      }

      const session: HuaweiSession = await response.json();
      
      // Redirect to Huawei OAuth page
      const huaweiOAuthUrl = `https://oauth2.fusionsolar.huawei.com/rest/dp/uidm/oauth2/v1/authorize?response_type=code&client_id=742215509&redirect_uri=https://huawei.srcful.dev/oauth/callback&state=${session.session_id}`;
      window.location.href = huaweiOAuthUrl;
    } catch (error) {
      console.error('Failed to create Huawei session:', error);
      toast.error('Failed to start Huawei authentication');
    } finally {
      setIsCreatingSession(false);
    }
  };

  if (selectedBrand === 'huawei') {
    return (
      <div className="space-y-8">
        <div className="space-y-6">
          <div className="flex items-center gap-4">
            <h2 className="text-xl font-semibold">Huawei FusionSolar Authentication</h2>
          </div>
          
          <div className="space-y-4 bg-gray-800/50 p-6 rounded-lg border border-gray-700">
            <div className="flex items-center gap-3">
              <HuaweiIcon height={30} className="text-white" />
              <h3 className="text-lg font-medium">Connect Your Huawei FusionSolar Account</h3>
            </div>
            <p className="text-gray-300">
              You'll be redirected to Huawei FusionSolar to authenticate and authorize access to your installation data. 
              After successful authentication, your gateway will be created automatically.<br/><br/>
              Note: Your FusionSolar account must have the owner role to be able to connect.
            </p>
            <div className="space-y-2 text-sm text-gray-400">
              <p>The process will:</p>
              <ul className="list-disc ml-4 space-y-1">
                <li>Redirect you to Huawei FusionSolar login</li>
                <li>Ask for your permission to access installation data</li>
                <li>Create your gateway automatically after approval</li>
                <li>Return you to this application</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <ActionButton
            onClick={onBack}
            variant="secondary"
            disabled={isCreatingSession}
          >
            Back
          </ActionButton>
          <ActionButton
            onClick={handleHuaweiAuth}
            variant="primary"
            isLoading={isCreatingSession}
            loadingText="Preparing Authentication..."
            disabled={!publicKey}
          >
            Continue to Huawei
          </ActionButton>
        </div>
      </div>
    );
  }

  // Original SolarEdge credentials form
  return (
    <div className="space-y-8">
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <h2 className="text-xl font-semibold">SolarEdge Credentials</h2>
        </div>
        <div className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="siteId" className="block text-sm font-medium text-gray-200">
              Site ID
            </label>
            <input
              type="text"
              id="siteId"
              value={credentials.siteId}
              onChange={(e) => setCredentials({ ...credentials, siteId: e.target.value })}
              className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
              placeholder="Enter your SolarEdge Site ID"
            />
          </div>

          <div className="space-y-2">
            <label htmlFor="apiKey" className="block text-sm font-medium text-gray-200">
              API Key
            </label>
            <div className="relative">
              <input
                type={showApiKey ? "text" : "password"}
                id="apiKey"
                value={credentials.apiKey}
                onChange={(e) => setCredentials({ ...credentials, apiKey: e.target.value })}
                className="w-full px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-gray-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent pr-10"
                placeholder="Enter your SolarEdge API Key"
              />
              <button
                type="button"
                onClick={() => setShowApiKey(!showApiKey)}
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400 hover:text-gray-300"
              >
                {showApiKey ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                )}
              </button>
            </div>
            <p className="mt-1 text-sm text-gray-400">
              You can generate an API key in your SolarEdge account settings
            </p>
          </div>

          <p className="text-sm text-gray-400">
            Need help finding your Site ID and API Key? 
            <a 
              href="https://help.energyid.eu/en/apps/solaredge/" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 ml-1"
            >
              View instructions
              <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-4 w-4 ml-1" viewBox="0 0 20 20" fill="currentColor">
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
            </a>
          </p>

          {validationError && (
            <div className="p-4 rounded-lg bg-red-900/50 border border-red-500/50">
              <p className="text-red-200">{validationError}</p>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={onBack}
          variant="secondary"
          disabled={isValidating}
        >
          Back
        </ActionButton>
        <ActionButton
          onClick={onNext}
          variant="primary"
          disabled={!credentials.siteId || !credentials.apiKey || isValidating}
          isLoading={isValidating}
          loadingText="Creating Gateway..."
        >
          Create Gateway
        </ActionButton>
      </div>
    </div>
  );
};

const LocationStep: FC<{
  location: LocationData;
  setLocation: (loc: LocationData) => void;
  onBack: () => void;
  onNext: () => void;
}> = ({ location, setLocation, onBack, onNext }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const marker = useRef<mapboxgl.Marker | null>(null);
  const [zoom] = useState<number>(4);
  const updateTimeout = useRef<NodeJS.Timeout>();

  const isValidLatitude = (lat: string) => {
    const num = parseFloat(lat);
    return !isNaN(num) && num >= -90 && num <= 90;
  };

  const isValidLongitude = (lng: string) => {
    const num = parseFloat(lng);
    return !isNaN(num) && num >= -180 && num <= 180;
  };

  const isValidLocation = isValidLatitude(location.latitude) && isValidLongitude(location.longitude);

  const updateLocationState = (lng: number, lat: number) => {
    if (updateTimeout.current) {
      clearTimeout(updateTimeout.current);
    }
    updateTimeout.current = setTimeout(() => {
      setLocation({
        longitude: lng.toFixed(6),
        latitude: lat.toFixed(6)
      });
    }, 100); // Debounce for 100ms
  };

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      const initialLng = parseFloat(location.longitude) || 10.9;
      const initialLat = parseFloat(location.latitude) || 50.2;

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/dark-v11",
        center: [initialLng, initialLat],
        zoom: zoom,
        minZoom: 2.7,
      });

      // Create a custom marker element
      const el = document.createElement('div');
      el.innerHTML = `
        <svg height="40px" width="40px" viewBox="0 0 293.334 293.334" xml:space="preserve" fill="#4ADE80"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path style="fill:#4ADE80;" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878 c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212 c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084 c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358 c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307 C198.98,120.938,175.559,144.358,146.667,144.358z"></path> <circle style="fill:#4ADE80;" cx="146.667" cy="90.196" r="21.756"></circle> </g> </g> </g></svg>
      `;
      el.style.cursor = 'pointer';

      // Create and store marker
      marker.current = new mapboxgl.Marker(el, { offset: [0, -20] })
        .setLngLat([initialLng, initialLat])
        .addTo(map.current);

      // Add move end handler for state updates
      map.current.on('moveend', () => {
        if (!map.current) return;
        const center = map.current.getCenter();
        updateLocationState(center.lng, center.lat);
      });

      // Update marker position during move
      map.current.on('move', () => {
        if (!map.current || !marker.current) return;
        const center = map.current.getCenter();
        marker.current.setLngLat([center.lng, center.lat]);
      });

      // Force a resize after initialization
      setTimeout(() => {
        map.current?.resize();
      }, 0);

      // Add resize handler
      const resizeHandler = () => {
        if (map.current) {
          map.current.resize();
        }
      };

      window.addEventListener('resize', resizeHandler);

      return () => {
        if (updateTimeout.current) {
          clearTimeout(updateTimeout.current);
        }
        window.removeEventListener('resize', resizeHandler);
        if (marker.current) {
          marker.current.remove();
          marker.current = null;
        }
        if (map.current) {
          map.current.remove();
          map.current = null;
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="space-y-8">
      <div className="space-y-4">
        <h2 className="text-xl font-semibold">Installation Location</h2>
        <p className="text-gray-300">Select the location of your solar installation:</p>

        <div className="bg-glass h-[300px] w-full overflow-hidden rounded-lg">
          <div ref={mapContainer} className="h-full w-full relative">
            <div className="absolute top-4 left-4 bg-glass text-gray-200 p-3 rounded-lg shadow-lg z-10">
              <p>Longitude: {location.longitude}</p>
              <p>Latitude: {location.latitude}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <ActionButton
          onClick={onBack}
          variant="secondary"
        >
          Back
        </ActionButton>
        <ActionButton
          onClick={onNext}
          variant="primary"
          disabled={!isValidLocation}
        >
          Next
        </ActionButton>
      </div>
    </div>
  );
};

const ErrorStep: FC<{
  error: string;
  gatewayId?: string;
  onBack: () => void;
}> = ({ error, gatewayId, onBack }) => (
  <div className="space-y-8">
    <div className="space-y-4">
      <h2 className="text-xl font-semibold text-red-500">Registration Failed</h2>
      <div className="p-4 rounded-lg bg-red-900/50 border border-red-500/50">
        <p className="text-red-200">{error}</p>
      </div>
      {gatewayId && (
        <div className="space-y-2">
          <p className="text-gray-300">This site is already registered as gateway:</p>
          <div className="p-4 bg-gray-800 rounded-lg">
            <a
              href={`https://explorer.sourceful.energy/egw/${gatewayId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="font-mono text-blue-400 hover:text-blue-300 flex items-center gap-2"
            >
              {gatewayId}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
            </a>
          </div>
        </div>
      )}
    </div>

    <div className="flex justify-between">
      <ActionButton
        onClick={onBack}
        variant="secondary"
      >
        Back
      </ActionButton>
    </div>
  </div>
);

const CloudOnboardingWizard: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [credentials, setCredentials] = useState<SolarEdgeCredentials>({
    siteId: '',
    apiKey: ''
  });
  const [location, setLocation] = useState<LocationData>({
    latitude: '',
    longitude: ''
  });
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [createdGatewayId, setCreatedGatewayId] = useState<string | null>(null);
  const [registrationError, setRegistrationError] = useState<{ message: string; gatewayId?: string } | null>(null);
  const { publicKey, signMessage } = useWallet();
  const { refreshGateways, claimNewGateway } = useGateways();
  const router = useRouter();

  const handleNext = async () => {
    if (currentStep === 1) {
      // Now this is the Location step
      setCurrentStep(prev => prev + 1);
    } else if (currentStep === 2) {
      // Now this is the Credentials step
      setIsValidating(true);
      setValidationError(null);

      try {
        const result = await SolarEdgeService.getSiteDetails(credentials.siteId, credentials.apiKey);
        if ('success' in result && result.success) {
          // Create the cloud gateway here since credentials are valid
          if (!publicKey || !signMessage) {
            toast.error('Please connect your wallet first');
            return;
          }

          try {
            const gatewayId = await createSolarEdgeCloudGateway(
              publicKey.toString(),
              credentials.siteId,
              credentials.apiKey,
              location.latitude,
              location.longitude
            );
            
            setCreatedGatewayId(gatewayId);
            await claimNewGateway(gatewayId);
            await refreshGateways();
            setCurrentStep(prev => prev + 1);
          } catch (error: any) {
            console.error('Failed to create cloud gateway:', error);
            
            const match = error.message.match(/Gateway already registered by ([\w-]+)/);
            const existingGatewayId = match ? match[1] : undefined;
            const isSolarEdgeCredentialError = error.message.includes('Invalid SolarEdge API credentials');
            
            setRegistrationError({
              message: existingGatewayId 
                ? 'This SolarEdge site has already been registered.'
                : isSolarEdgeCredentialError
                  ? 'The SolarEdge API credentials are not valid. Please verify your Site ID and API Key.'
                  : error.message === 'Failed to sign message'
                    ? 'Failed to sign the registration message. Please try again.'
                    : 'Failed to create cloud gateway. Please try again.',
              gatewayId: existingGatewayId
            });
            setCurrentStep(4); // Error step
          }
        } else {
          setValidationError('Failed to validate credentials. Please check your Site ID and API Key.');
        }
      } catch (error) {
        console.error('Failed to validate SolarEdge credentials:', error);
        setValidationError(
          'Failed to validate your SolarEdge credentials. Please check your Site ID and API Key and try again.'
        );
        toast.error('Invalid credentials');
      } finally {
        setIsValidating(false);
      }
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    setValidationError(null);
    setRegistrationError(null);
    // If we're in the error step (4), go back to credentials step (2)
    if (currentStep === 4) {
      setCurrentStep(2);
    } else {
      setCurrentStep(prev => prev - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <IntroStep 
            onNext={handleNext} 
            selectedBrand={selectedBrand}
            onSelectBrand={setSelectedBrand}
          />
        );
      case 1:
        return (
          <LocationStep
            location={location}
            setLocation={setLocation}
            onBack={handleBack}
            onNext={handleNext}
          />
        );
      case 2:
        if (!selectedBrand) {
          // Handle error case or redirect back
          setCurrentStep(0);
          return null;
        }
        return (
          <CredentialsStep 
            credentials={credentials}
            setCredentials={setCredentials}
            onBack={handleBack}
            onNext={handleNext}
            isValidating={isValidating}
            validationError={validationError}
            selectedBrand={selectedBrand}
            location={location}
            publicKey={publicKey?.toString() || null}
          />
        );
      case 3:
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-white">Gateway Created Successfully!</h2>
            <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
              <InfoRow
                label="Gateway ID"
                value={createdGatewayId || ''}
                isCopyable
              />
              <InfoRow
                label="Wallet"
                value={publicKey?.toString() || ''}
                displayValue={publicKey ? 
                  `${publicKey.toString().slice(0, 4)}...${publicKey.toString().slice(-4)}` : 
                  ''
                }
                isCopyable
              />
              <p className="mt-2 text-sm text-green-300">
                Your gateway is successfully registered to your wallet.
              </p>
            </div>

            <div className="flex justify-end">
              <ActionButton
                onClick={() => router.navigate({ to: `/gateway/${createdGatewayId}` })}
                variant="primary"
              >
                Finish
              </ActionButton>
            </div>
          </div>
        );
      case 4:
        return registrationError ? (
          <ErrorStep
            error={registrationError.message}
            gatewayId={registrationError.gatewayId}
            onBack={handleBack}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <ContentFramework>
      <PageContainer>
        <GatewayPageHeader 
          gatewayName="Onboard Cloud Gateway"
        />
        
        {/* Progress indicator */}
        <div className="mt-8 mb-8">
          <div className="relative flex justify-between">
            {/* Step labels */}
            <div className="absolute left-0 right-0 -top-6">
              <div className="flex justify-between">
                {['Introduction', 'Location', 'Credentials', 'Complete'].map((step) => (
                  <span key={step} className="text-sm font-medium text-gray-400">
                    {step}
                  </span>
                ))}
              </div>
            </div>

            {/* Progress line background */}
            <div className="absolute left-0 top-4 h-0.5 w-full bg-gray-700" />
            
            {/* Progress line */}
            <div
              className="absolute left-0 top-4 h-0.5 bg-green-500 transition-all duration-500"
              style={{ 
                width: `${(Math.min(currentStep, 3) / 3) * 100}%`
              }}
            />

            {/* Step circles */}
            <div className="relative flex w-full justify-between">
              {['Introduction', 'Location', 'Credentials', 'Complete'].map((step, index) => (
                <div
                  key={step}
                  className={`flex h-8 w-8 items-center justify-center rounded-full border-2 text-sm font-semibold transition-colors duration-500 ${
                    index < currentStep
                      ? 'border-green-500 bg-green-500 text-white'
                      : index === currentStep
                      ? 'border-blue-500 bg-blue-500 text-white'
                      : 'border-gray-700 bg-gray-800 text-gray-400'
                  }`}
                >
                  {index + 1}
                </div>
              ))}
            </div>
          </div>
        </div>

        {renderStep()}
      </PageContainer>
    </ContentFramework>
  );
};

export default CloudOnboardingWizard; 