import { FC } from 'react';
import { FaSpinner } from "react-icons/fa";
import InfoRow from "../../components/InfoRow";
import * as ble from "../../services/ble";
import { toast } from "react-hot-toast";
import { gatewayInfo } from '../../constants/infoTexts';
import WifiConfigForm from '../form/WifiConfigForm';

export enum NetworkState {
  CHECKING_NAME,
  SCANNING_WIFI,
  SHOW_FORM,
  CONNECTED
}

export interface NetworkData {
  state: NetworkState;
  networks: string[];
  selectedNetwork: string | null;
  password: string;
  gatewayName?: string;
}

// Pure function to check gateway name
export async function checkGatewayName(
  bleApi: ble.Api,
  currentState: NetworkState
): Promise<{ newState: NetworkState; name?: string }> {
  if (currentState !== NetworkState.CHECKING_NAME) {
    return { newState: currentState };
  }

  try {
    const nameResponse = await bleApi.fetch(ble.API_NAME, ble.Method.GET, {});
    if (nameResponse.payload.name && !nameResponse.payload.exception) {
      return { 
        newState: NetworkState.CONNECTED,
        name: nameResponse.payload.name 
      };
    }
    return { newState: NetworkState.SCANNING_WIFI };
  } catch (error) {
    console.error('Name check failed:', error);
    return { newState: NetworkState.SCANNING_WIFI };
  }
}

// Pure function to scan for networks
export async function scanNetworks(
  bleApi: ble.Api,
  currentState: NetworkState
): Promise<{ newState: NetworkState; networks?: string[] }> {
  if (currentState !== NetworkState.SCANNING_WIFI) {
    return { newState: currentState };
  }

  try {
    await new Promise(resolve => setTimeout(resolve, 2000));
    const wifiResponse = await bleApi.fetch(ble.API_WIFI, ble.Method.GET, {});
    
    if (wifiResponse.payload.ssids) {
      return {
        newState: NetworkState.SHOW_FORM,
        networks: wifiResponse.payload.ssids
      };
    }
    return { newState: NetworkState.SHOW_FORM };
  } catch (error) {
    console.error('WiFi scan failed:', error);
    toast.error('Failed to scan for networks');
    return { newState: NetworkState.SHOW_FORM };
  }
}

// Pure function to submit network configuration
export async function submitNetworkConfig(
  bleApi: ble.Api,
  selectedNetwork: string | null,
  password: string
): Promise<{ success: boolean; error?: string }> {
  if (!selectedNetwork) {
    return { success: false, error: 'Please select a network' };
  }

  try {
    await bleApi.fetch(ble.API_WIFI, ble.Method.POST, {
      ssid: selectedNetwork,
      psk: password
    });
    return { success: true };
  } catch (error) {
    console.error('Failed to configure WiFi:', error);
    return { 
      success: false, 
      error: 'Failed to configure WiFi. Please try again.' 
    };
  }
}

interface NetworkStepProps {
  state: NetworkState;
  networks: string[];
  selectedNetwork: string | null;
  password: string;
  gatewayName?: string;
  bleApi: ble.Api;
  setState: (state: NetworkState) => void;
}

const NetworkStep: FC<NetworkStepProps> = ({
  state,
  networks,
  selectedNetwork,
  password,
  gatewayName,
  bleApi,
  setState
}) => {
  if (state === NetworkState.CONNECTED && gatewayName) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Network Connected</h2>
        <div className="bg-green-500/10 p-4 rounded-lg border border-green-500/20">
          <InfoRow
            label={gatewayInfo.name.label}
            tooltip={gatewayInfo.name.tooltip}
            value={gatewayName}
            isCopyable
          />
          <p className="mt-4 text-sm text-green-300">
            Your gateway is successfully connected to the network.
          </p>
        </div>
      </div>
    );
  }

  if (state === NetworkState.CHECKING_NAME) {
    return (
      <div className="space-y-6 text-center">
        <h2 className="text-2xl font-bold text-white">Checking Connection Status</h2>
        <div className="flex flex-col items-center space-y-4">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
          <p className="text-gray-300">
            Checking gateway connection...
          </p>
        </div>
      </div>
    );
  }

  if (state === NetworkState.SCANNING_WIFI) {
    return (
      <div className="space-y-6 text-center">
        <h2 className="text-2xl font-bold text-white">Scanning for Networks</h2>
        <div className="flex flex-col items-center space-y-4">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
          <p className="text-gray-300">
            Scanning for available WiFi networks...
          </p>
        </div>
      </div>
    );
  }

  const handleWifiSubmit = async (ssid: string, psk: string) => {
    try {
      await bleApi.fetch(ble.API_WIFI, ble.Method.POST, {
        ssid,
        psk
      });
      
      // Set state to checking immediately after successful submission
      setState(NetworkState.CHECKING_NAME);
      
      // After successful submission, check the name to update state
      const result = await checkGatewayName(bleApi, NetworkState.CHECKING_NAME);
      if (result.newState === NetworkState.CONNECTED) {
        toast.success('Successfully connected to network');
      }
      
      return { success: true };
    } catch (error) {
      console.error('Failed to configure WiFi:', error);
      toast.error('Failed to configure WiFi. Please try again.');
      return { success: false };
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Configure Internet Connection</h2>
      <WifiConfigForm 
        availableSSIDs={networks}
        onSubmit={handleWifiSubmit}
        isSubmitting={false}
        showScanButton={false}
        initialSSID={selectedNetwork || ''}
        initialPassword={password}
      />
    </div>
  );
};

export default NetworkStep; 