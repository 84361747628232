import { Link, useMatchRoute } from "@tanstack/react-router";
import { ChevronDown, ExternalLink } from "lucide-react";
import { UilCircuit } from '@iconscout/react-unicons';
import { motion, AnimatePresence } from "framer-motion";
import { ReactNode } from 'react';

interface NavItemChild {
  title: string;
  path: string;
  external?: boolean;
  badge?: number;
}

interface NavItemProps {
  title: string;
  path?: string;
  children?: NavItemChild[];
  isActive?: boolean;
  isExpanded?: boolean;
  onClick?: () => void;
  onExpand?: () => void;
  onMobileMenuClose?: () => void;
  icon?: ReactNode;
  onItemClick?: () => void;
  badge?: number;
}

const NavItem = ({ 
  title, 
  path, 
  children, 
  isActive, 
  isExpanded, 
  onClick, 
  onExpand,
  onMobileMenuClose,
  icon,
  onItemClick,
}: NavItemProps) => {
  const matchRoute = useMatchRoute();

  if (children) {
    return (
      <div>
        <button
          onClick={() => {
            if (onExpand) onExpand();
            if (onClick) onClick();
          }}
          className={`flex w-full items-center justify-between rounded-lg px-4 py-2 text-gray-300 hover:bg-gray-600/50 group ${
            isExpanded ? "bg-gray-800/50 font-semibold text-white" : ""
          }`}
        >
          <div className="flex items-center">
            {icon || <UilCircuit className="h-5 w-5 mr-2" />}
            <span>{title}</span>
          </div>
          <motion.div
            initial={false}
            animate={{ rotate: isExpanded ? -180 : 0 }}
            transition={{ duration: 0.2 }}
          >
            <ChevronDown className={`h-4 w-4 ${isExpanded ? "text-white" : ""}`} />
          </motion.div>
        </button>
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overflow-hidden"
            >
              <div className="ml-4 py-1">
                {children.map((child) => {
                  const isChildActive = matchRoute({ to: child.path, fuzzy: false });
                  return (
                    <Link
                      key={child.path}
                      to={child.path}
                      onClick={onItemClick}
                      className={`group flex items-center justify-between rounded-lg px-4 py-2 ${
                        isChildActive 
                          ? "!bg-green-600 font-bold text-white" 
                          : "text-gray-400 hover:text-gray-300 hover:bg-gray-600/50"
                      }`}
                    >
                      <span>{child.title}</span>
                      <div className="flex items-center">
                        {child.badge && child.badge > 0 && (
                          <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-green-500 text-white rounded-full">
                            {child.badge}
                          </span>
                        )}
                        {child.external && (
                          <ExternalLink className="ml-2 h-3 w-3" />
                        )}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }

  const isExactMatch = path ? matchRoute({ to: path, fuzzy: false }) : false;

  return (
    <Link
      to={path!}
      className={`group flex items-center rounded-lg p-2 text-gray-300 ${
        isExactMatch ? "!bg-green-600 font-bold text-white" : ""
      } hover:bg-gray-600/50`}
      onClick={onClick}
    >
      {icon || <UilCircuit className="h-5 w-5 mr-2" />}
      {title}
    </Link>
  );
};

export default NavItem; 