import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect } from "react";
import { useWalletContext } from "../context/WalletContext";
import { router } from '../router';
import { AuthLayout } from '../components/layout/AuthLayout';
import { FaDiscord } from 'react-icons/fa';
import { HomeIcon, ChatBubbleLeftRightIcon } from "@heroicons/react/24/outline";

const LoginPage = () => {
  const { isConnected, isConnecting, resetConnection } = useWalletContext();

  useEffect(() => {
    if (isConnected) {
      router.navigate({ 
        to: '/',
        replace: true
      });
    }
  }, [isConnected]);

  return (
    <AuthLayout>
      <h1 className="text-3xl font-bold text-white">Sourceful Energy Configurator</h1>
      <p className="text-center text-gray-300">
        Please connect your Solana wallet to continue,<br/>we recommend using <a href="https://phantom.app" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 decoration-blue-400/30 hover:decoration-blue-300 transition-colors">Phantom</a>.
      </p>
      <div className="rounded-lg bg-gray-800/50 p-4">
        <WalletMultiButton />
      </div>
      {isConnecting && (
        <button
          onClick={resetConnection}
          className="text-sm text-gray-400 hover:text-white"
        >
          Reset Connection
        </button>
      )}

      <div className="mt-6 text-center">
        <div className="text-gray-600 mb-2">or</div>
        <a 
          href="https://app.sourceful.energy" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center space-x-2 px-4 py-2 bg-gray-800/50 hover:bg-gray-700/50 text-white rounded-md transition-colors"
        >
          <span className="text-gray-300">Get the Native App</span>
        </a>
        <p className="text-gray-400 text-sm mt-3">Available for iOS and Android</p>
      </div>
      
      {/* Footer with links */}
      <div className="mt-auto pt-8">
        <div className="flex flex-col sm:flex-row justify-center sm:space-x-8 space-y-4 sm:space-y-0">
          <a
            href="https://sourceful.energy"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors"
          >
            <HomeIcon className="h-5 w-5" />
            <span>Sourceful Energy</span>
          </a>
          <a
            href="https://discord.com/invite/srcful"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors"
          >
            <FaDiscord className="h-5 w-5" />
            <span>Discord</span>
          </a>
          <a
            href="https://support.sourceful.energy"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center space-x-2 text-blue-400 hover:text-blue-300 transition-colors"
          >
            <ChatBubbleLeftRightIcon className="h-5 w-5" />
            <span>Support</span>
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginPage; 