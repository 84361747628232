import { FaSpinner } from "react-icons/fa";
import { ReactNode } from "react";

interface ActionButtonProps {
  onClick?: (e: React.MouseEvent) => void;
  type?: "submit" | "button";
  isLoading?: boolean;
  disabled?: boolean;
  loadingText?: string;
  children: ReactNode;
  variant?: "primary" | "destructive" | "secondary";
  icon?: ReactNode;
  className?: string;
}

export function ActionButton({
  onClick,
  type = "button",
  isLoading = false,
  disabled = false,
  loadingText = "Loading...",
  children,
  variant = "primary",
  icon,
  className = "",
}: ActionButtonProps) {
  const baseStyles = "flex items-center justify-center space-x-2 px-3 py-2 rounded-md transition-colors font-medium";
  
  const variantStyles = {
    primary: "bg-green-600 hover:bg-green-500 text-white disabled:bg-green-500/50",
    destructive: "bg-red-500/10 text-red-400 hover:bg-red-500/20 disabled:bg-red-500/5 disabled:text-red-400/50",
    secondary: "bg-gray-700 hover:bg-gray-600 text-gray-200 disabled:bg-gray-700/50"
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`${baseStyles} ${variantStyles[variant]} ${
        (disabled || isLoading) ? "cursor-not-allowed opacity-50" : ""
      } ${className}`}
    >
      {isLoading ? (
        <>
          <FaSpinner className="animate-spin" />
          <span>{loadingText}</span>
        </>
      ) : (
        <>
          {icon && <span className="h-4 w-4">{icon}</span>}
          <span>{children}</span>
        </>
      )}
    </button>
  );
}
