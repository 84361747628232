import { FC } from 'react';
import InfoRow from "../../components/InfoRow";
import { FaSpinner } from "react-icons/fa";
import { gatewayInfo } from '../../constants/infoTexts';
import { toast } from "react-hot-toast";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

export interface CryptoInfo {
  serialNumber: string;
  publicKey: string;
}

export interface CryptoInfoStepProps {
  cryptoInfo: CryptoInfo;
  isLoading: boolean;
  isRegisteredToOtherWallet?: boolean;
  gatewayExists?: boolean;
}

const CryptoInfoStep: FC<CryptoInfoStepProps> = ({ 
  cryptoInfo, 
  isLoading,
  isRegisteredToOtherWallet = false,
  gatewayExists = true
}) => {
  const handleCopySupport = () => {
    const supportText = `Gateway ID: ${cryptoInfo.serialNumber}\nGateway Public Key: ${cryptoInfo.publicKey}`;
    navigator.clipboard.writeText(supportText)
      .then(() => toast.success('Copied gateway information to clipboard'))
      .catch(() => toast.error('Failed to copy to clipboard'));
  };

  if (isLoading) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Gateway Information</h2>
        <div className="flex justify-center items-center py-8">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
        </div>
      </div>
    );
  }

  if (!gatewayExists) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Gateway Not Found</h2>
        <div className="p-4 bg-yellow-900/50 border border-yellow-700 rounded-lg text-yellow-200">
          <p>This gateway needs to be added before it can be onboarded. Please:</p>
          <ol className="list-decimal ml-6 mt-2 space-y-2">
            <li>Verify you are onboarding the correct gateway</li>
            <li>
              Contact support with the following information:
              <ul className="ml-4 mt-1 space-y-1">
                <li>Gateway ID: <span className="font-mono bg-yellow-950/50 px-1 rounded">{cryptoInfo.serialNumber}</span></li>
                <li className="break-all">Gateway Public Key: <span className="font-mono bg-yellow-950/50 px-1 rounded">{cryptoInfo.publicKey}</span></li>
              </ul>
              <div className="mt-4 flex gap-2">
                <button
                  onClick={handleCopySupport}
                  className="flex items-center gap-2 px-3 py-2 text-sm rounded-lg bg-yellow-900/50 hover:bg-yellow-900/70 border border-yellow-700/50 transition-colors"
                >
                  <DocumentDuplicateIcon className="h-4 w-4" />
                  Copy Gateway Information
                </button>
                <a
                  href="https://support.sourceful.energy/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-3 py-2 text-sm rounded-lg bg-yellow-900/50 hover:bg-yellow-900/70 border border-yellow-700/50 transition-colors"
                >
                  <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                  Contact Support
                </a>
              </div>
            </li>
          </ol>
        </div>
      </div>
    );
  }

  if (isRegisteredToOtherWallet) {
    return (
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Gateway Already Registered</h2>
        <div className="space-y-4 text-gray-300">
          <div className="bg-red-500/10 p-4 rounded-lg border border-red-500/20">
            <p className="text-red-200 break-all">
              This gateway (ID: {cryptoInfo.serialNumber}) is already registered to another wallet.
              You cannot proceed with the setup as you are not the owner of this gateway.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Gateway Information</h2>
      <div className="space-y-4 text-gray-300">
        <p>
          Below is important information about your gateway. Please save these details
          as they may be needed for support purposes.
        </p>
        
        <div className="bg-blue-500/10 p-4 rounded-lg border border-blue-500/20">
          <div className="break-all">
            <InfoRow
              label={gatewayInfo.serial.label}
              tooltip={gatewayInfo.serial.tooltip}
              value={cryptoInfo.serialNumber}
              isCopyable
            />
            <InfoRow
              label={gatewayInfo.publicKey.label}
              tooltip={gatewayInfo.publicKey.tooltip}
              value={cryptoInfo.publicKey}
              displayValue={`${cryptoInfo.publicKey.slice(0, 8)}...${cryptoInfo.publicKey.slice(-8)}`}
              isCopyable
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CryptoInfoStep; 