import { useEffect, useState } from 'react';
import { useNavigate, Link } from '@tanstack/react-router';
import PageContainer from './layout/PageContainer';
import { ActionButton } from './ui/ActionButton';

export interface OAuthError {
  type: string;
  code: string;
  description: string;
  duplicateGatewayId: string;
}

// Utility functions
export const storeOAuthError = (params: URLSearchParams) => {
  const errorType = params.get('type');
  
  if (errorType?.includes('oauth_error')) {
    const error = {
      type: errorType,
      code: params.get('code') || '',
      description: params.get('description') || 'OAuth authentication failed',
      duplicateGatewayId: params.get('duplicate_gw_id') || '',
    };
    localStorage.setItem('oauth_error', JSON.stringify(error));
    return error;
  }
  return null;
};

export const clearOAuthError = () => {
  localStorage.removeItem('oauth_error');
};

export const getStoredOAuthError = (): OAuthError | null => {
  const stored = localStorage.getItem('oauth_error');
  return stored ? JSON.parse(stored) : null;
};

const formatErrorDescription = (description: string) => {
  return description.replace(/_/g, ' ');
};

export const OAuthErrorHandler = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<OAuthError | null>(null);

  useEffect(() => {
    const storedError = getStoredOAuthError();
    if (storedError) {
      setError(storedError);
    }
  }, []);

  if (!error) return null;

  const handleRetry = () => {
    clearOAuthError();
    setError(null);
    
    if (error.type === 'huawei_oauth_error') {
      navigate({ to: '/onboarding/cloud' });
    }
  };

  const handleCancel = () => {
    clearOAuthError();
    setError(null);
  };

  const renderErrorMessage = () => {
    console.log(error);
    if (error.code === 'srcful_13') {
      return (
        <>
          <p className="text-gray-300 mb-2">
            This plant has already been registered with another gateway
            {error.duplicateGatewayId && (
              <>
                {' '}Existing gateway: {' '}
                <Link 
                  to="/gateway/$gatewayId"
                  params={{ gatewayId: error.duplicateGatewayId }}
                  className="text-blue-400 hover:text-blue-300 underline"
                >
                  {error.duplicateGatewayId}
                </Link>
              </>
            )}
            .
          </p>
          <p className="text-gray-300 mb-2">
            Please log in using another account in your cloud provider.
          </p>
        </>
      );
    }

    return (
      <p className="text-gray-300 mb-2">
        {formatErrorDescription(error.description)}
      </p>
    );
  };

  return (
    <div className="mt-8">
      <PageContainer>
        <section className="bg-red-900/20 border border-red-900/50 rounded-lg p-4">
          <h1 className="mb-10 font-srcful text-4xl font-bold text-gray-200">
            Authentication Error
          </h1>
          <h2 className="text-base font-bold leading-7 text-gray-200 mb-2">
            Error Details
          </h2>
          {renderErrorMessage()}
          <p className="text-gray-400 text-sm mb-8">
            Error Code: {error.code}
          </p>
          <div className="flex justify-end gap-4">
            <ActionButton onClick={handleCancel} variant="secondary">
              Dismiss
            </ActionButton>
            <ActionButton onClick={handleRetry} variant="primary">
              Try Again
            </ActionButton>
          </div>
        </section>
      </PageContainer>
    </div>
  );
}; 