import { useState } from 'react';
import { EyeIcon, EyeSlashIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { ActionButton } from "../ui/ActionButton";

interface WifiConfigFormProps {
  availableSSIDs: string[];
  onSubmit: (ssid: string, password: string) => Promise<{ success: boolean }>;
  onScan?: () => Promise<void>;
  isScanning?: boolean;
  isSubmitting?: boolean;
  showScanButton?: boolean;
  initialSSID?: string;
  initialPassword?: string;
}

const WifiConfigForm: React.FC<WifiConfigFormProps> = ({
  availableSSIDs,
  onSubmit,
  onScan,
  isScanning = false,
  isSubmitting = false,
  showScanButton = true,
  initialSSID = '',
  initialPassword = ''
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [manualInput, setManualInput] = useState(!availableSSIDs.includes(initialSSID) && initialSSID !== '');
  const [formData, setFormData] = useState({
    ssid: initialSSID,
    password: initialPassword
  });

  const handleSSIDChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === 'manual') {
      setManualInput(true);
      setFormData(prev => ({ ...prev, ssid: '' }));
    } else {
      setManualInput(false);
      setFormData(prev => ({ ...prev, ssid: value }));
    }
  };

  const handleManualSSIDChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData(prev => ({ ...prev, ssid: value }));
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData(prev => ({ ...prev, password: value }));
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    await onSubmit(formData.ssid, formData.password);
  };

  return (
    <form onSubmit={handleSubmitForm} className="space-y-4">
      <div>
        <label htmlFor="ssid" className="block text-sm font-medium mb-1">
          SSID
        </label>
        {!manualInput ? (
          <div className="flex gap-2">
            <select
              id="ssid"
              value={formData.ssid || ''}
              onChange={handleSSIDChange}
              className="flex-1 bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
              disabled={isSubmitting || isScanning}
            >
              <option value="">Select a network...</option>
              {availableSSIDs.map((ssid: string) => (
                <option key={ssid} value={ssid}>
                  {ssid}
                </option>
              ))}
              <option value="manual">Enter manually...</option>
            </select>
            {showScanButton && onScan && (
              <ActionButton
                onClick={onScan}
                disabled={isScanning || isSubmitting}
                isLoading={isScanning}
                loadingText="Scanning..."
                icon={<ArrowPathIcon className="h-4 w-4" />}
              >
                Scan Wifi
              </ActionButton>
            )}
          </div>
        ) : (
          <div className="flex gap-2">
            <input
              type="text"
              value={formData.ssid}
              onChange={handleManualSSIDChange}
              className="flex-1 bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
              placeholder="Network name"
              disabled={isSubmitting}
            />
            <ActionButton
              onClick={() => {
                setManualInput(false);
                setFormData(prev => ({ ...prev, ssid: '' }));
              }}
              variant="secondary"
            >
              Back to list
            </ActionButton>
          </div>
        )}
      </div>
      
      <div>
        <label htmlFor="password" className="block text-sm font-medium mb-1">
          Password
        </label>
        <div className="relative">
          <input
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={handlePasswordChange}
            className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 pr-10 text-sm"
            placeholder="Network password"
            disabled={isSubmitting}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400 hover:text-gray-300"
          >
            {showPassword ? (
              <EyeSlashIcon className="h-4 w-4" />
            ) : (
              <EyeIcon className="h-4 w-4" />
            )}
          </button>
        </div>
      </div>

      <div className="flex justify-end">
        <ActionButton
          type="submit"
          disabled={isSubmitting || !formData.ssid || !formData.password}
          isLoading={isSubmitting}
          loadingText="Configuring..."
        >
          Configure WiFi
        </ActionButton>
      </div>
    </form>
  );
};

export default WifiConfigForm; 