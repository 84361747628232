import { IoCopyOutline } from "react-icons/io5";
import { toast } from "react-hot-toast";
import { ReactNode } from "react";
import { Tooltip } from "./ui/Tooltip";

interface InfoRowProps {
  label: string;
  value?: string | null;
  displayValue?: ReactNode;
  isCopyable?: boolean;
  tooltip?: ReactNode;
}

const InfoRow = ({ label, value, displayValue, isCopyable = false, tooltip }: InfoRowProps) => {
  const handleCopy = async () => {
    if (!value) return;
    
    try {
      await navigator.clipboard.writeText(value);
      toast.success('Copied to clipboard');
    } catch (err) {
      console.error('Failed to copy:', err);
      toast.error('Failed to copy to clipboard');
    }
  };

  return (
    <div className="py-2 md:flex md:justify-between md:pr-4">
      <div className="flex items-center">
        <dt className="text-lg font-semibold leading-6 text-white">{label}</dt>
        {tooltip && <Tooltip content={tooltip} className="-mt-0.5 ml-2" />}
      </div>
      <dd className="text-lg mt-1 leading-6 text-white sm:mt-0 flex items-center gap-2">
        <span>{displayValue || value || "Not set"}</span>
        {isCopyable && value && (
          <button
            onClick={handleCopy}
            className="p-1 hover:bg-white/10 rounded-md transition-colors"
            title="Copy to clipboard"
          >
            <IoCopyOutline className="w-4 h-4" />
          </button>
        )}
      </dd>
    </div>
  );
};

export default InfoRow; 