import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

interface TooltipProps {
  content: ReactNode;
  className?: string;
}

export const Tooltip = ({ content, className = "" }: TooltipProps) => {
  return (
    <div className="relative inline-block group">
      <InformationCircleIcon className={`h-4 w-4 text-gray-500 hover:text-gray-300 cursor-help ${className}`} />
      <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 text-sm bg-gray-800 text-gray-200 rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 whitespace-normal min-w-[200px] max-w-[300px] shadow-xl z-50 border border-gray-700">
        <div className="relative">
          {content}
          <div className="absolute -bottom-1 left-1/2 -translate-x-1/2 translate-y-full w-2 h-2 bg-gray-800 rotate-45 border-b border-r border-gray-700" />
        </div>
      </div>
    </div>
  );
}; 