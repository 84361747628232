import { FC, useState } from 'react';
import { useBle } from "../../context/BleContext";
import { toast } from "react-hot-toast";
import * as ble from "../../services/ble/";
import { ActionButton } from "../../components/ui/ActionButton";

const DeviceScanStep: FC = () => {
  const bleApi = useBle();
  const [isScanning, setIsScanning] = useState(false);

  const handleScan = async () => {
    setIsScanning(true);
    try {
      await bleApi.fetch(ble.API_DEVICE_SCAN, ble.Method.GET, {}, 0, {timeout: 1000*60*5, retries: 3});
      toast.success('Device scan initiated');
    } catch (error) {
      console.error('Failed to start device scan:', error);
      toast.error('Failed to start device scan');
    } finally {
      setIsScanning(false);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-white">Device Discovery</h2>
      
      <div className="space-y-4 text-gray-300">
        <p>
          Your gateway can now scan the network for compatible devices such as inverters and meters.
        </p>
        
        <div className="bg-blue-500/10 p-4 rounded-lg border border-blue-500/20">
          <h3 className="font-semibold mb-2">Before scanning:</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>Make sure devices are on the same network as the gateway</li>
            <li>Scanning involves connecting to devices on your network, this may cause disruptions</li>
            <li>Advanced users that run e.g. a proxy are NOT recommended to scan their network.</li>
          </ul>
        </div>

        <div className="bg-yellow-500/10 p-4 rounded-lg border border-yellow-500/20">
          <p className="text-yellow-200">
            You can skip this step and perform device scanning later from the gateway's device management page. 
            Manual device configuration is also available there.
          </p>
        </div>
      </div>

      <ActionButton
        onClick={handleScan}
        disabled={isScanning}
        isLoading={isScanning}
        loadingText="Scanning..."
        className="w-full"
      >
        Start Device Scan
      </ActionButton>
    </div>
  );
};

export default DeviceScanStep; 