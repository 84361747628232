import { EGWTPResponse } from "./EGWTPResponse";
import { IManager } from "./Manager.interface";

// API-Endpoints
export const API_NAME = "/api/name";
export const API_WIFI = "/api/wifi";
export const API_WIFI_SCAN = "/api/wifi/scan";
export const API_UPTIME = "/api/uptime";
export const API_INVERTER = "/api/inverter";
export const API_IPADDRESS = "/api/network/address";
export const API_CREATE_DEVICE = "/api/device";
export const API_INITIALIZE = "/api/initialize";
export const API_VERSION = "/api/version";
export const API_INVERTER_SUPPORTED = "/api/inverter/supported";
export const API_SCAN_INVERTER = "/api/inverter/modbus/scan";
export const API_CRYPTO = "/api/crypto";
export const API_NOTIFICATION = "/api/notification";
export const API_STATE_UPDATE = "/api/state/update";
export const API_DEVICE_SCAN = "/api/device/scan";

const SERVICE_UUID = "0FDA92B2-44A2-4AF2-84F5-FA682BAA2B8D".toLowerCase();
const REQUEST_CHARACTERISTIC_UUID =
  "51FF12BB-3ED8-46E5-B4F9-D64E2FEC021B".toLowerCase();
const RESPONSE_CHARACTERISTIC_UUID =
  "51FF12BB-3ED8-46E5-B4F9-D64E2FEC021C".toLowerCase();

export type ObserverCallback = (response: EGWTPResponse) => void;

export class Manager implements IManager {
  device: BluetoothDevice | null = null;
  requestQueue: string[] = [];
  observers: ObserverCallback[] = [];
  isProcessingRequest: boolean = false;
  _isConnected: boolean = false;

  connectionObservers: ((m: IManager, status: string) => void)[] = [];
  erorObservers: ((m: IManager, error: DOMException) => void)[] = [];

  subscribeToConnectionStatus = (
    callback: (m: IManager, status: string) => void,
  ) => {
    this.connectionObservers.push(callback);
  };

  unsubscribeFromConnectionStatus = (
    callback: (m: IManager, status: string) => void,
  ) => {
    this.connectionObservers = this.connectionObservers.filter(
      (observer) => observer !== callback,
    );
  };

  subscribeToExceptions = (
    callback: (m: IManager, error: DOMException) => void,
  ) => {
    this.erorObservers.push(callback);
  };

  unsubscribeFromExceptions = (
    callback: (m: IManager, error: DOMException) => void,
  ) => {
    this.erorObservers = this.erorObservers.filter(
      (observer) => observer !== callback,
    );
  };

  notifyException = (error: DOMException) => {
    for (let callback of this.erorObservers) {
      callback(this, error);
    }
  };

  // this is the internal ble api callback function
  notifyConnectionStatusCallback = () => {
    this.notifyConnectionStatusChange("");
  };

  notifyConnectionStatusChange = (status: string) => {
    for (let callback of this.connectionObservers) {
      callback(this, status);
    }
  };

  connect = async () => {

    if (!await this.isResponsive()) {
      console.log("Bluetooth not responsive");
      return false;
    }

    if (!this.device) {
      console.log("Requesting device");
      this.device = await this.requestDevice();
      console.log("Device: ", this.device);
      if (!this.device) {
        console.log("No device found");
        return false;
      }
    }

    console.log("Connecting to device");
    return this.connectToDevice(this.device);
  };

  subscribe = (observerCallback: ObserverCallback) => {
    // check that the observer is not already subscribed
    if (!this.observers.includes(observerCallback)) {
      this.observers.push(observerCallback);
    }
  };

  unsubscribe = (observerCallback: ObserverCallback) => {
    this.observers = this.observers.filter(
      (observer) => observer !== observerCallback,
    );
  };

  notifyObservers = (response: EGWTPResponse) => {
    for (const observerCallback of this.observers) {
      observerCallback(response);
    }
  };

  isConnected(): boolean {
    if (this.device && this.device.gatt) {
      return this.device.gatt.connected && this._isConnected;
    }
    return false;
  }

  async isResponsive(): Promise<boolean> {
    let timeoutId;
    
    try {
      const availabilityPromise = navigator.bluetooth.getAvailability();
      const timeoutPromise = new Promise<never>((_, reject) => {
        timeoutId = setTimeout(() => {
          reject(new Error('Bluetooth getAvailability request timed out'));

        }, 60 * 1000);
      });

      await Promise.race([availabilityPromise, timeoutPromise]);
      clearTimeout(timeoutId);
      return true;
    } catch (error) {
      clearTimeout(timeoutId);
      return false;
    }
  }

  async requestDevice(): Promise<BluetoothDevice> {
    const options = {
      filters: [{ services: [SERVICE_UUID] }],
      optionalServices: [SERVICE_UUID],
    };

    return await navigator.bluetooth.requestDevice(options);
  }

  async connectToDevice(
    device: BluetoothDevice,
    retries: number = 0,
  ): Promise<boolean> {
    if (this.isConnected()) {
      console.log("Already connected to device");
      this.notifyConnectionStatusChange("Already connected to device");
      return true;
    }

    console.log("Connecting to device...");
    this.notifyConnectionStatusChange(
      "BLE Connection process initiated on " + device.name + "...",
    );
    console.log(device);
    try {
      device.addEventListener("gattserverdisconnected", this.onDisconnected);

      this.notifyConnectionStatusChange("Connecting to GATT server...");
      const server = await device.gatt?.connect();
      if (!server) {
        console.log("GATT server not found");
        throw new Error("GATT server not found");
      }
      console.log("Connected to server");
      this.notifyConnectionStatusChange("Connected to GATT server");

      const service = await server.getPrimaryService(SERVICE_UUID);
      if (!service) {
        throw Error("Service not found");
      }
      console.log("Connected to service");

      const reqCharacteristic = await service.getCharacteristic(
        REQUEST_CHARACTERISTIC_UUID,
      );
      const respCharacteristic = await service.getCharacteristic(
        RESPONSE_CHARACTERISTIC_UUID,
      );

      if (!reqCharacteristic || !respCharacteristic) {
        console.log(
          reqCharacteristic.uuid +
            " or " +
            respCharacteristic.uuid +
            " not found",
        );
        throw new Error(
          reqCharacteristic.uuid +
            " or " +
            respCharacteristic.uuid +
            " not found",
        );
      }
      console.log("Connected to characteristics");
      this.notifyConnectionStatusChange(
        "Started notifications on response characteristic",
      );

      respCharacteristic.addEventListener(
        "characteristicvaluechanged",
        this.handleResponseHttpCharacteristic,
      );
      await respCharacteristic.startNotifications();
      console.log("Started notifications on response characteristic");
      this.notifyConnectionStatusChange(
        "Started notifications on response characteristic",
      );

      this.clearRequestQueue();
      this.setupQueueProcessor();
      this._isConnected = true;
      this.notifyConnectionStatusChange("Connected!");

      console.log("Connected to device");
    } catch (error) {
      console.log(error);
      if (retries < 5) {
        console.log("Retrying to connect to device");
        this.notifyConnectionStatusChange(
          "Retrying to connect attempt: " + (retries + 1) + "/5",
        );
        return this.connectToDevice(device, retries + 1);
      }
      this._isConnected = false;
      throw error;
    }

    return true;
  }

  disconnect = async () => {
    await this.disconnectProcedure();
    this.notifyConnectionStatusChange("Disconnected by user");
  };

  disconnectProcedure = async () => {
    try {
      if (this.device?.gatt?.connected) {
        this.device.removeEventListener(
          "gattserverdisconnected",
          this.onDisconnected,
        );
        console.log("Stopped notifications for gatt server connection");

        const service = await this.device.gatt.getPrimaryService(SERVICE_UUID);
        const respCharacteristic = await service.getCharacteristic(
          RESPONSE_CHARACTERISTIC_UUID,
        );
        await respCharacteristic.stopNotifications();
        respCharacteristic.removeEventListener(
          "characteristicvaluechanged",
          this.handleResponseHttpCharacteristic,
        );
        console.log("Stopped notifications on response characteristic");
       
      }
      if (this.device?.gatt) {
        this.device.gatt.disconnect();
        console.log("Disconnected from device");
      }
    } catch (error) {
      console.log("Error while disconnecting", error);
    }

    // Forget is for some reason part of the interface but it seems to not be implemented in mobile phones
    if (typeof this.device?.forget === "function") {
      console.log("Forgetting ble device...");

      this.device.forget();
    } else {
      console.log("Device: ", this.device);
      console.log(typeof this.device?.forget);
      console.log("Forgetting ble device failed");
    }

    this._isConnected = false;
    console.log("Nulling ble device");
    this.device = null;
  };

  onDisconnected = () => {
    this.disconnectProcedure();
    console.log("Bluetooth disconnected");
    this.notifyConnectionStatusChange("Disconnected");
  };

  async send(
    endpoint: string,
    method: string,
    payload: Record<string, any> = {},
    offset: number = 0,
  ) {
    console.log("Queueing request to " + endpoint);

    const packet = await this.buildPacket(
      endpoint,
      method,
      JSON.stringify(payload),
      offset,
    );
    this.addToRequestQueue(packet);
  }

  async processMessage(message: string) {
    // attempt to connect/reconnect
    if (!this.device?.gatt?.connected) {
      try {
        await this.connect();
      } catch (error) {
        if (error instanceof DOMException) {
          this.notifyException(error);
          console.error("Caught a DOMException: ", error.name, error.message);
        } else {
          console.log("Error while sending message", error);
          throw error;
        }
      }
    }

    if (!this.device?.gatt?.connected) {
      return;
    }

    console.log("Sending request: ", message);
    const service = await this.device.gatt.getPrimaryService(SERVICE_UUID);
    const characteristic = await service.getCharacteristic(
      REQUEST_CHARACTERISTIC_UUID,
    );

    try {
      await characteristic.writeValueWithoutResponse(
        new TextEncoder().encode(message),
      );
      console.log("Sent request!");
    } catch (error) {
      if (error instanceof DOMException) {
        this.notifyException(error);
        console.error("Caught a DOMException: ", error.name, error.message);
      } else {
        console.log("Error while sending message", error);
        throw error;
      }
    }
  }

  setupQueueProcessor() {
    console.log("Setting up queue processor");
    const intervalId = setInterval(async () => {
      if (!this.device?.gatt?.connected) {
        return;
      }
      if (this.requestQueueLength() > 0 && !this.isProcessingRequest) {
        this.isProcessingRequest = true;
        const message = this.getFirstFromRequestQueue();
        if (message) {
          this.removeFirstFromRequestQueue();
          await this.processMessage(message);
        }
        this.isProcessingRequest = false;
      }
    }, 1000);

    return intervalId;
  }

  async buildPacket(
    endpoint: string,
    method: string,
    content: string,
    offset: number,
  ) {
    const protocol = "EGWTTP/1.1\r\n";
    const contentType = "Content-Type: text/json\r\n";
    const contentLength = `Content-Length: ${new TextEncoder().encode(content).byteLength}\r\n`;
    const offsetString = offset > 0 ? `Offset: ${offset}\r\n` : "";
    const sendMessageData = `${method} ${endpoint} ${protocol}${contentType}${offsetString}${contentLength}\r\n${content}`;
    return sendMessageData;
  }

  addToRequestQueue(item: string) {
    if (this.requestQueue.includes(item)) {
      console.log("Item already in queue, skipping");
      return;
    }
    this.requestQueue.push(item);
    console.log("Added to queue, queue length: ", this.requestQueue.length);
  }

  clearRequestQueue() {
    this.requestQueue = [];
  }

  removeFirstFromRequestQueue() {
    if (this.requestQueue.length > 0) {
      this.requestQueue.splice(0, 1);
    }
  }

  getFirstFromRequestQueue() {
    if (this.requestQueue.length > 0) {
      return this.requestQueue[0];
    }
  }

  requestQueueLength() {
    return this.requestQueue.length;
  }

  // arrow function needed to keep this context
  handleResponseHttpCharacteristic = async (e: any) => {
    const decoder = new TextDecoder("utf-8");
    // we apparently get a DataView object, so we need to convert it to a Uint8Array
    const buffer = new Uint8Array(e.target.value.buffer);
    //console.log("Received response buffer as array: ", Array.from(buffer));
    const resp = new EGWTPResponse(Array.from(buffer));
    console.log("Recieved decoded message:", decoder.decode(e.target.value));
    console.log("Parsed into egwtp response: ", resp);
    this.notifyObservers(resp);
  };
}
