import { useState, useEffect } from "react";
import { useGateways } from "../../context/GatewayContext";
import { useWallet } from "@solana/wallet-adapter-react";
import toast from "react-hot-toast";
import SrcfulMessageProtocolFactory from '../../services/backend/SrcfulMessageProtocolFactory';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { handleSubmitFlow } from "../utils/gatewayUtils";
import { ActionButton } from "../ui/ActionButton";

interface Inverter {
  name: string;
  dname: string;
  proto: string;
}

interface ConfiguredDevice {
  is_open: boolean;
  id: string;
  name: string;
  connection: {
    ip: string;
    port: number;
    connection: string;
  };
}

type BasePayload = {
  connection: string;
  device_type: string;
};

type TcpPayload = BasePayload & {
  ip: string;
  port: number;
  slave_id: number;
};

type SolarmanPayload = TcpPayload & {
  sn: number;
};

type SunspecPayload = BasePayload & {
  ip: string;
  port: number;
  slave_id: number;
};

type RestPayload = BasePayload & {
  ip: string;
  iq_gw_serial: string;
  username: string;
  password: string;
};

interface InverterConfig {
  connection: string;
  device_type: string;
  ip: string;
  port: number;
  slave_id: number;
}



export default function FormGatewayInverter() {
  const { publicKey, signMessage } = useWallet();
  const { activeGateway } = useGateways();
  
  const [ipAddress, setIpAddress] = useState<string>("");
  const [port, setPort] = useState<number>();
  const [deviceAddress, setDeviceAddress] = useState<number>(1);
  const [selectedInverter, setSelectedInverter] = useState<string>("");
  const [selectedMake, setSelectedMake] = useState<string>("");
  const [selectedProtocol, setSelectedProtocol] = useState<string>("");
  const [supportedInverters, setSupportedInverters] = useState<Inverter[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingDeviceConfig, setPendingDeviceConfig] = useState<null | { ip: string; connection: string }>(null);
  const [serial, setSerial] = useState<string>("");
  const [enphase_username, setEnphaseUsername] = useState<string>("");
  const [enphase_password, setEnphasePassword] = useState<string>("");
  const [enphase_gw_serial, setEnphaseGwSerial] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (activeGateway?.state?.devices?.supported?.inverters) {
      setSupportedInverters(activeGateway.state.devices.supported.inverters);
    }
  }, [activeGateway]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!publicKey || !activeGateway || !signMessage) return;
    setIsSubmitting(true);

    let deviceConfig: TcpPayload | SolarmanPayload | SunspecPayload | RestPayload;

    switch (selectedProtocol) {
      case "sol":
        deviceConfig = {
          connection: "SOLARMAN",
          device_type: selectedMake,
          ip: ipAddress,
          port: Number(port),
          slave_id: deviceAddress,
          sn: Number(serial),
        };
        break;

      case "sunspec":
        deviceConfig = {
          connection: "SUNSPEC",
          device_type: selectedMake,
          ip: ipAddress,
          port: Number(port),
          slave_id: deviceAddress,
        };
        break;

      case "rest":
        deviceConfig = {
          connection: "ENPHASE",
          device_type: selectedMake,
          ip: ipAddress,
          iq_gw_serial: enphase_gw_serial,
          username: enphase_username,
          password: enphase_password,
        };
        break;

      default: // TCP or mb
        deviceConfig = {
          connection: "TCP",
          device_type: selectedMake,
          ip: ipAddress,
          port: Number(port),
          slave_id: deviceAddress,
        };
    }


    const deviceConfigForMessage = (config: TcpPayload | SolarmanPayload | SunspecPayload | RestPayload): InverterConfig => {
      if ('port' in config && 'slave_id' in config) {
        return config as InverterConfig;
      }
      
      // For REST/Enphase, provide default values for required fields
      return {
        ...config,
        port: 80, // default HTTP port
        slave_id: 1, // default slave ID
      };
    };

    const message = SrcfulMessageProtocolFactory.createDeviceConfigMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.serial,
      deviceConfig: deviceConfigForMessage(deviceConfig),
      deviceDisplayName: `Inverter (${selectedMake})`
    });

    await handleSubmitFlow(message, signMessage, () => {
      setPendingDeviceConfig({
        ip: deviceConfig.ip,
        connection: deviceConfig.connection
      });
    }, () => {
      toast.error('Failed to connect inverter');
      setIsSubmitting(false);
    });
      
  };

  // Watch for the device to appear in the gateway state
  useEffect(() => {
    if (!pendingDeviceConfig || !activeGateway?.state?.devices?.configured) return;

    const deviceExists = activeGateway.state.devices.configured.some(
      (device: ConfiguredDevice) => 
        device.connection.ip === pendingDeviceConfig.ip && 
        device.connection.connection === pendingDeviceConfig.connection
    );

    if (deviceExists) {
      setIsSubmitting(false);
      setPendingDeviceConfig(null);
      toast.success('Inverter successfully configured and connected');
      // Reset form
      setIpAddress("");
      setPort(undefined);
      setSelectedInverter("");
    }
  }, [activeGateway?.state?.devices?.configured, pendingDeviceConfig]);

  // Add a timeout to reset the form if device doesn't appear after 30 seconds
  useEffect(() => {
    if (!pendingDeviceConfig) return;

    const timeout = setTimeout(() => {
      if (isSubmitting) {
        setIsSubmitting(false);
        setPendingDeviceConfig(null);
        toast.error('Inverter configuration timed out. Please try again.');
      }
    }, 30000);

    return () => clearTimeout(timeout);
  }, [pendingDeviceConfig, isSubmitting]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    const [inverterMake, inverterProtocol] = value.split("|");
    
    setSelectedInverter(value);
    setSelectedMake(inverterMake);
    setSelectedProtocol(inverterProtocol);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-2">Inverter Type</label>
        <select
          value={selectedInverter}
          onChange={handleChange}
          className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
          disabled={isSubmitting}
          required
        >
          <option value="" disabled hidden>
            Select an inverter
          </option>
          {supportedInverters.map((inverter) => (
            <option
              key={inverter.name}
              value={`${inverter.name}|${inverter.proto}`}
            >
              {inverter.dname}
            </option>
          ))}
        </select>
      </div>

      {selectedInverter && (
        <>
          <div>
            <label className="block text-sm font-medium mb-2">IP Address</label>
            <input
              type="text"
              value={ipAddress}
              onChange={(e) => setIpAddress(e.target.value)}
              placeholder="192.168.1.100"
              className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
              disabled={isSubmitting}
              required
            />
          </div>

          {selectedProtocol !== "rest" && (
            <>
              <div>
                <label className="block text-sm font-medium mb-2">Port</label>
                <input
                  type="number"
                  value={port}
                  onChange={(e) => setPort(Number(e.target.value))}
                  placeholder="502"
                  className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
                  disabled={isSubmitting}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">Device Address</label>
                <input
                  type="number"
                  value={deviceAddress}
                  onChange={(e) => setDeviceAddress(Number(e.target.value))}
                  placeholder="1"
                  className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
                  disabled={isSubmitting}
                  required
                />
              </div>
            </>
          )}

          {selectedProtocol === "sol" && (
            <div>
              <label className="block text-sm font-medium mb-2">Serial</label>
              <input
                type="text"
                value={serial}
                onChange={(e) => setSerial(e.target.value)}
                placeholder="Serial"
                className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
                disabled={isSubmitting}
                required
              />
            </div>
          )}

          {selectedProtocol === "rest" && (
            <>
              <div>
                <label className="block text-sm font-medium mb-2">
                  Enphase Cloud Username (Enlighten account email)
                </label>
                <input
                  type="text"
                  value={enphase_username}
                  onChange={(e) => setEnphaseUsername(e.target.value)}
                  placeholder="your.email@example.com"
                  className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">
                  Enphase Cloud Password (Enlighten account password)
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={enphase_password}
                    onChange={(e) => setEnphasePassword(e.target.value)}
                    placeholder="Your Enphase Enlighten password"
                    className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
                    disabled={isSubmitting}
                    required
                  />
                  <button
                    type="button"
                    className="absolute right-2 top-1/2 -translate-y-1/2 transform text-gray-600"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                  </button>
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Enphase IQ Gateway Serial</label>
                <input
                  type="text"
                  value={enphase_gw_serial}
                  onChange={(e) => setEnphaseGwSerial(e.target.value)}
                  placeholder="IQ Gateway Serial"
                  className="w-full bg-gray-700 rounded-md border border-gray-600 px-3 py-2 text-sm"
                  disabled={isSubmitting}
                  required
                />
              </div>
            </>
          )}
        </>
      )}

      <div className="flex justify-end">
        <ActionButton
          type="submit"
          isLoading={isSubmitting}
          loadingText="Configuring..."
          disabled={isSubmitting}
        >
          Add Inverter
        </ActionButton>
      </div>
    </form>
  );
} 