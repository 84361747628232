import { FC, useState } from 'react';
import ContentFramework from "../components/ContentFramework";
import { useWallet } from "@solana/wallet-adapter-react";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { useGateways } from "../context/GatewayContext";
import { Button } from '../components/ui/Button';
import { toast } from "react-hot-toast";

const DebugPage: FC = () => {
  const { connected, publicKey, wallet } = useWallet();
  const { gateways, activeGateway, isLoading, error } = useGateways();
  const [isStorageExpanded, setIsStorageExpanded] = useState(false);
  const [isWalletExpanded, setIsWalletExpanded] = useState(false);
  const [isGatewayExpanded, setIsGatewayExpanded] = useState(false);
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});

  const getLocalStorageItems = () => {
    const items: Record<string, { isJson: boolean; value: any }> = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key) {
        const value = localStorage.getItem(key) || '';
        try {
          const parsedValue = JSON.parse(value);
          items[key] = { 
            isJson: typeof parsedValue === 'object' && parsedValue !== null, 
            value: parsedValue 
          };
        } catch {
          items[key] = { isJson: false, value: value };
        }
      }
    }
    return items;
  };

  const toggleExpand = (path: string) => {
    setExpandedItems(prev => ({
      ...prev,
      [path]: !prev[path]
    }));
  };

  const renderJsonValue = (value: any, path: string = ''): JSX.Element => {
    if (typeof value === 'object' && value !== null) {
      const isExpanded = expandedItems[path];
      const keyName = path.split('.').pop() || '';
      return (
        <div>
          <div className="flex items-center">
            <button
              onClick={() => toggleExpand(path)}
              className="flex items-center space-x-2 text-sm text-gray-400 hover:text-gray-200"
            >
              {isExpanded ? (
                <IoChevronDown className="w-4 h-4 flex-shrink-0" />
              ) : (
                <IoChevronForward className="w-4 h-4 flex-shrink-0" />
              )}
              <span className="text-blue-400">
                {keyName}
                {Array.isArray(value) && ` (${value.length})`}
              </span>
            </button>
          </div>
          {isExpanded && (
            <div className="ml-4 mt-2 space-y-1">
              {Object.entries(value).map(([key, val]) => (
                <div key={key} className="break-all">
                  {typeof val === 'object' && val !== null ? (
                    renderJsonValue(val, `${path}.${key}`)
                  ) : (
                    <div className="flex flex-wrap">
                      <span className="text-blue-400 mr-2">{key}:</span>
                      <span className="text-gray-200 break-all whitespace-pre-wrap">
                        {JSON.stringify(val)}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
    return (
      <span className="text-gray-200 break-all whitespace-pre-wrap">
        {JSON.stringify(value)}
      </span>
    );
  };

  const ExpandableSection = ({ 
    title, 
    isExpanded, 
    setIsExpanded, 
    children 
  }: { 
    title: string;
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
    children: React.ReactNode;
  }) => (
    <div className="bg-glass p-4 rounded-lg">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center space-x-2 w-full text-left"
      >
        <span className="text-xl font-semibold">{title}</span>
        {isExpanded ? (
          <IoChevronDown className="w-5 h-5" />
        ) : (
          <IoChevronForward className="w-5 h-5" />
        )}
      </button>
      {isExpanded && (
        <div className="mt-4 space-y-2">
          {children}
        </div>
      )}
    </div>
  );

  const handleClearLocalStorage = () => {
    window.localStorage.clear();
    toast.success('Local storage cleared');
    // Force a re-render to update the displayed storage items
    setIsStorageExpanded(false);
    setTimeout(() => setIsStorageExpanded(true), 0);
  };

  return (
    <ContentFramework>
      <div className="w-full max-w-3xl p-4 text-gray-200">
        <h1 className="text-3xl font-bold mb-8">Debug</h1>
        
        <div className="space-y-4">
          {/* Gateway Context Section */}
          <ExpandableSection
            title="Gateway Context"
            isExpanded={isGatewayExpanded}
            setIsExpanded={setIsGatewayExpanded}
          >
            <div className="space-y-2">
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Loading State</div>
                <div className="mt-1 font-mono text-sm">
                  {isLoading ? "Loading..." : "Ready"}
                </div>
              </div>
              
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Error State</div>
                <div className="mt-1 font-mono text-sm break-all">
                  {error ? error.message : "No errors"}
                </div>
              </div>

              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Active Gateway</div>
                <div className="font-mono text-sm w-full break-all">
                  {renderJsonValue(activeGateway || {}, 'activeGateway')}
                </div>
              </div>

              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">All Gateways</div>
                <div className="font-mono text-sm w-full break-all">
                  {renderJsonValue(gateways || [], 'gateways')}
                </div>
              </div>
            </div>
          </ExpandableSection>

          {/* Wallet Information Section */}
          <ExpandableSection
            title="Wallet Information"
            isExpanded={isWalletExpanded}
            setIsExpanded={setIsWalletExpanded}
          >
            <div className="space-y-2">
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Connection Status</div>
                <div className="mt-1 font-mono text-sm">
                  {connected ? "Connected" : "Disconnected"}
                </div>
              </div>
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Wallet Public Key</div>
                <div className="mt-1 font-mono text-sm break-all">
                  {publicKey?.toBase58() || "Not available"}
                </div>
              </div>
              <div className="border border-gray-700 rounded p-3">
                <div className="font-mono text-sm text-blue-400">Wallet Name</div>
                <div className="mt-1 font-mono text-sm break-words">
                  {wallet?.adapter.name || "Not available"}
                </div>
              </div>
            </div>
          </ExpandableSection>

          {/* Local Storage Section */}
          <ExpandableSection
            title="Local Storage"
            isExpanded={isStorageExpanded}
            setIsExpanded={setIsStorageExpanded}
          >
            <div className="space-y-4">
              <div className="flex justify-end">
                <Button
                  onClick={handleClearLocalStorage}
                  variant="secondary"
                  className="bg-red-500 hover:bg-red-600 text-white border-red-400"
                >
                  Clear Local Storage
                </Button>
              </div>

              {Object.entries(getLocalStorageItems()).map(([key, { isJson, value }]) => (
                <div key={key} className="border border-gray-700 rounded p-3 w-full">
                  {isJson ? (
                    <div className="font-mono text-sm w-full">
                      {renderJsonValue(value, key)}
                    </div>
                  ) : (
                    <>
                      <div className="font-mono text-sm text-blue-400 break-all">{key}</div>
                      <div className="mt-1 font-mono text-sm break-all">
                        {value}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </ExpandableSection>
        </div>
      </div>
    </ContentFramework>
  );
};

export default DebugPage; 