import { useGateways, isHardwareGateway } from "../context/GatewayContext";
import ContentFramework from "../components/ContentFramework";
import { useWallet } from "@solana/wallet-adapter-react";
import { useParams } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { gatewayOverviewRoute } from "../router";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import InfoRow from "../components/InfoRow";
import { DerInfo } from "../services/backend/SrcfulPublicApi";
import GatewayPageHeader from '../components/GatewayPageHeader';
import { gatewayInfo } from '../constants/infoTexts';
import PageContainer from '../components/layout/PageContainer';
import { GatewayIcon, CloudGatewayIcon } from '../components/icons/icons';
import { ActionButton } from '../components/ui/ActionButton';
import { toast } from "react-hot-toast";

const formatLastSeen = (timestamp: number) => {
  const now = Date.now();
  const diff = Math.floor((now - timestamp) / 1000);
  const minutes = Math.floor(diff / 60);
  const date = new Date(timestamp);
  const userLocale = navigator.language;
  const timeStr = date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit' });
  
  if (minutes < 1) return `Just now (${timeStr})`;
  if (minutes === 1) return `1 minute ago (${timeStr})`;
  if (minutes < 60) return `${minutes} minutes ago (${timeStr})`;
  
  // If more than 24 hours, show full date
  if (diff > 86400) {
    return date.toLocaleString(userLocale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  
  // If more than an hour but less than 24 hours
  const hours = Math.floor(minutes / 60);
  return `${hours} hour${hours > 1 ? 's' : ''} ago (${timeStr})`;
};

const formatDerLastSeen = (isoTimestamp: string) => {
  const date = new Date(isoTimestamp);
  const userLocale = navigator.language;
  
  // If more than 24 hours ago, show full date
  const now = Date.now();
  const diff = now - date.getTime();
  
  if (diff > 86400000) { // more than 24 hours
    return date.toLocaleString(userLocale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  }
  
  // If less than 24 hours, show relative time
  const minutes = Math.floor(diff / 60000);
  const timeStr = date.toLocaleTimeString(userLocale, { hour: '2-digit', minute: '2-digit' });
  
  if (minutes < 1) return `Just now (${timeStr})`;
  if (minutes === 1) return `1 minute ago (${timeStr})`;
  if (minutes < 60) return `${minutes} minutes ago (${timeStr})`;
  
  const hours = Math.floor(minutes / 60);
  return `${hours} hour${hours > 1 ? 's' : ''} ago (${timeStr})`;
};

const formatUptime = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);

  const parts = [];
  if (days > 0) parts.push(`${days}d`);
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  
  // If less than a minute, show seconds
  if (parts.length === 0) {
    return `${Math.floor(seconds)}s`;
  }

  return parts.join(' ');
};

const DerSection = ({ ders }: { ders?: DerInfo[] }) => {
  if (!ders || ders.length === 0) {
    return null;
  }

  return (
    <>
      <div className="h-px bg-gray-200/10 my-8" />
      <div>
        <h2 className="text-base font-bold leading-7">
          Distributed Energy Resources
        </h2>
      </div>
      <div className="mt-5">
        {ders.map((der) => (
          <InfoRow
            key={der.sn}
            label={der.type}
            tooltip={gatewayInfo.der_lastSeen.tooltip}
            value={der.lastSeen}
            displayValue={
              <span className={`${
                new Date(der.lastSeen).getTime() < Date.now() - 15 * 60 * 1000 
                ? 'text-red-500' 
                : ''
              }`}>
                {formatDerLastSeen(der.lastSeen)}
              </span>
            }
          />
        ))}
      </div>
    </>
  );
};

const ReauthenticationSection = ({ gatewayId, publicKey }: { gatewayId: string; publicKey: string | null }) => {
  const [isCreatingSession, setIsCreatingSession] = useState(false);

  const handleReauth = async () => {
    if (!publicKey) {
      toast.error('Please connect your wallet first');
      return;
    }

    setIsCreatingSession(true);
    try {
      const response = await fetch('https://huawei.srcful.dev/gateway_session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ownerWallet: publicKey,
          type: 'reauth_oauth',
          redirect_url: 'https://app.srcful.io',
          gateway_serial: gatewayId
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create Huawei session');
      }

      const session = await response.json();
      
      // Redirect to Huawei OAuth page
      const huaweiOAuthUrl = `https://oauth2.fusionsolar.huawei.com/rest/dp/uidm/oauth2/v1/authorize?response_type=code&client_id=742215509&redirect_uri=https://huawei.srcful.dev/oauth/callback&state=${session.session_id}`;
      window.location.href = huaweiOAuthUrl;
    } catch (error) {
      console.error('Failed to create Huawei session:', error);
      toast.error('Failed to start Huawei authentication');
    } finally {
      setIsCreatingSession(false);
    }
  };

  return (
    <div className="mt-8 space-y-4">
      <div className="flex items-center gap-4">
        <h2 className="text-xl font-semibold">Reauthentication</h2>
      </div>
      <div className="space-y-4 bg-gray-800/50 p-6 rounded-lg border border-gray-700">
        <p className="text-gray-300">
          If you need to refresh your Huawei FusionSolar authentication, you can do so here. 
          This will redirect you to Huawei's login page to reauthorize access to your installation data.
        </p>
        <ActionButton
          onClick={handleReauth}
          variant="primary"
          isLoading={isCreatingSession}
          loadingText="Preparing Authentication..."
          disabled={!publicKey}
        >
          Reauthorize Huawei Access
        </ActionButton>
      </div>
    </div>
  );
};

const GatewayOverview = () => {
  const { connected } = useWallet();
  const { gateways, activeGateway, setActiveGateway } = useGateways();
  const { gatewayId } = useParams({ from: gatewayOverviewRoute });
  const [lastSeen, setLastSeen] = useState('');
  const { publicKey } = useWallet();

  useEffect(() => {
    if (gatewayId && gateways) {
      const gateway = gateways.find(g => g.id === gatewayId);
      if (gateway) {
        setActiveGateway(gateway);
      }
    }
  }, [gatewayId, gateways, setActiveGateway]);

  useEffect(() => {
    const updateLastSeen = () => {
      if (activeGateway?.state?.timestamp) {
        setLastSeen(formatLastSeen(activeGateway.state.timestamp));
      }
    };

    updateLastSeen(); // Initial call
    const intervalId = setInterval(updateLastSeen, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [activeGateway?.state?.timestamp]);

  if (!connected || !activeGateway) return null;

  const formatPublicKey = (key: string) => {
    if (!key) return "not in backend";
    return `${key.slice(0, 4)}...${key.slice(-4)}`;
  };

  return (
    <ContentFramework>
      <PageContainer>
        <GatewayPageHeader 
          gatewayName={activeGateway.name}
        />
        <h1 className="text-4xl font-bold mb-6">Overview</h1>

        {/* Gateway Information */}
        <div className="w-full">
          <div>
            <h2 className="text-base font-bold leading-7">
              Energy Gateway Information
            </h2>
          </div>
          <div className="mt-5">
              <InfoRow
                label={gatewayInfo.name.label}
                value={activeGateway.name}
                isCopyable={true}
                tooltip={gatewayInfo.name.tooltip}
              />
              <InfoRow
                label="Type"
                value={isHardwareGateway(activeGateway) ? "Hardware Gateway" : "Cloud Gateway"}
                displayValue={
                  <div className="flex items-center gap-2">
                    {isHardwareGateway(activeGateway) ? (
                      <GatewayIcon state="none" className="w-5 h-5" />
                    ) : (
                      <CloudGatewayIcon state="none" className="w-5 h-5" />
                    )}
                    <span>{isHardwareGateway(activeGateway) ? "Hardware Gateway" : "Cloud Gateway"}</span>
                  </div>
                }
                tooltip="The type of gateway - either a physical hardware gateway or a cloud-based gateway"
              />
              <InfoRow
                label={gatewayInfo.serial.label}
                value={activeGateway.serial}
                displayValue={
                  <a
                    href={`https://explorer.sourceful.energy/egw/${activeGateway.serial}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:bg-white/10 rounded-md transition-colors px-2 py-1"
                  >
                    <span>{activeGateway.serial}</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </a>
                }
                isCopyable={true}
                tooltip={gatewayInfo.serial.tooltip}
              />
              {isHardwareGateway(activeGateway) && (
                <InfoRow
                  label={gatewayInfo.publicKey.label}
                  value={activeGateway.publicKey}
                  displayValue={formatPublicKey(activeGateway.publicKey)}
                  isCopyable={true}
                  tooltip={gatewayInfo.publicKey.tooltip}
                />
              )}
              <InfoRow
                label={gatewayInfo.h3Index.label}
                value={activeGateway.h3Index}
                displayValue={
                  <a
                    href={`https://explorer.sourceful.energy/hex/${activeGateway.h3Index}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 hover:bg-white/10 rounded-md transition-colors px-2 py-1"
                  >
                    <span>{activeGateway.h3Index}</span>
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </a>
                }
                isCopyable={true}
                tooltip={gatewayInfo.h3Index.tooltip}
              />
              {activeGateway.state?.timestamp && (
                <InfoRow
                  label={gatewayInfo.lastSeen.label}
                  value={activeGateway.state.timestamp.toString()}
                  displayValue={
                    <span className={`${Date.now() - activeGateway.state.timestamp > 15 * 60 * 1000 ? 'text-red-500' : ''}`}>
                      {lastSeen}
                    </span>
                  }
                  tooltip={gatewayInfo.lastSeen.tooltip}
                />
              )}
              {isHardwareGateway(activeGateway) && (
                <>
                  <InfoRow
                    label={gatewayInfo.uptime.label}
                    value={activeGateway.state?.status?.uptime?.toString()}
                    displayValue={activeGateway.state?.status?.uptime ? formatUptime(activeGateway.state.status.uptime) : 'Unknown'}
                    tooltip={gatewayInfo.uptime.tooltip}
                  />
                  <InfoRow
                    label={gatewayInfo.firmware.label}
                    value={activeGateway.state?.status?.version}
                    displayValue={activeGateway.state?.status?.version || 'Unknown'}
                    tooltip={gatewayInfo.firmware.tooltip}
                  />
                </>
              )}
          </div>
        </div>

        {/* DER Section */}
        <DerSection ders={activeGateway.ders} />

        {/* Reauthentication Section - only for Huawei cloud gateways */}
        {activeGateway.id.startsWith('hhw-') && (
          <ReauthenticationSection 
            gatewayId={activeGateway.id} 
            publicKey={publicKey?.toString() || null} 
          />
        )}
      </PageContainer>
    </ContentFramework>
  );
};

export default GatewayOverview; 