import React, { useState, ChangeEvent } from 'react';
import { fetchGatewayStatus } from '../services/backend/SrcfulPublicApi';
import ContentFramework from '../components/ContentFramework';
import PageContainer from '../components/layout/PageContainer';
import InfoRow from '../components/InfoRow';
import { Link } from '@tanstack/react-router';

const GatewayStatusCheck: React.FC = () => {
  const [gatewayId, setGatewayId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<{ exists: boolean; wallet: boolean; location: boolean } | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGatewayId(e.target.value);
  };

  const handleCheck = async () => {
    if (!gatewayId.trim()) {
      setError('Please enter a gateway ID');
      return;
    }

    setLoading(true);
    setError(null);
    setStatus(null);

    try {
      const statusResult = await fetchGatewayStatus(gatewayId);
      setStatus(statusResult);
    } catch (err) {
      setError('Failed to fetch gateway status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentFramework>
      <PageContainer>
        <h1 className="text-4xl font-bold mb-6">Gateway Status Check</h1>

        <div className="w-full">
          <div>
            <h2 className="text-base font-bold leading-7">
              Check Gateway Status
            </h2>
          </div>

          <div className="mt-5 space-y-4">
            <div className="flex gap-4 items-start">
              <input
                type="text"
                className="flex-1 max-w-md px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg text-gray-200 focus:outline-none focus:border-blue-500"
                placeholder="Enter Gateway ID"
                value={gatewayId}
                onChange={handleChange}
              />
              <button
                className={`px-4 py-2 rounded-lg bg-blue-600 text-white font-medium hover:bg-blue-700 transition-colors
                  ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleCheck}
                disabled={loading}
              >
                {loading ? 'Checking...' : 'Check Status'}
              </button>
            </div>

            {error && (
              <div className="p-4 bg-red-900/50 border border-red-700 rounded-lg text-red-200">
                {error}
              </div>
            )}

            {status && !status.exists && (
              <div className="p-4 bg-yellow-900/50 border border-yellow-700 rounded-lg text-yellow-200">
                <h3 className="font-semibold mb-2">Gateway Not Found</h3>
                <p>This gateway needs to be added before it can be onboarded. Please:</p>
                <ol className="list-decimal ml-6 mt-2 space-y-2">
                  <li>Verify you have the correct Gateway ID</li>
                  <li>
                    Use the <Link 
                      to="/connect-gw" 
                      className="text-blue-400 hover:text-blue-300 underline"
                    >
                      Gateway Information
                    </Link> tool to get the gateway ID and public key
                  </li>
                  <li>
                    Contact support with the following information:
                    <ul className="list-disc ml-6 mt-1">
                      <li>Gateway ID</li>
                      <li>Gateway Public Key</li>
                    </ul>
                  </li>
                </ol>
              </div>
            )}

            {status && status.exists && (
              <div>
                <h2 className="text-base font-bold leading-7 mb-5">
                  Gateway Status Information
                </h2>
                <InfoRow
                  label="Gateway ID"
                  value={gatewayId}
                  isCopyable={true}
                  tooltip="The unique identifier of the gateway"
                />
                <InfoRow
                  label="Wallet Status"
                  value={status.wallet ? 'Assigned' : 'Not Assigned'}
                  displayValue={
                    <span className={status.wallet ? 'text-green-500' : 'text-yellow-500'}>
                      {status.wallet ? 'Assigned' : 'Not Assigned'}
                    </span>
                  }
                  tooltip="Whether the gateway is assigned to a wallet"
                />
                <InfoRow
                  label="Location Status"
                  value={status.location ? 'Set' : 'Not Set'}
                  displayValue={
                    <span className={status.location ? 'text-green-500' : 'text-yellow-500'}>
                      {status.location ? 'Set' : 'Not Set'}
                    </span>
                  }
                  tooltip="Whether the gateway has a location set"
                />
              </div>
            )}
          </div>
        </div>
      </PageContainer>
    </ContentFramework>
  );
};

export default GatewayStatusCheck; 