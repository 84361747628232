import GatewayPageHeader from '../components/GatewayPageHeader';
import { useParams } from '@tanstack/react-router';
import SrcfulMessageProtocolFactory from '../services/backend/SrcfulMessageProtocolFactory';
import { useGateways } from '../context/GatewayContext';
import { useEffect, useState } from 'react';
import ContentFramework from '../components/ContentFramework';
import { useWallet } from "@solana/wallet-adapter-react";
import { toast } from "react-hot-toast";
import { IoCopyOutline } from "react-icons/io5";
import { ActionButton } from "../components/ui/ActionButton";
import { TrashIcon } from "@heroicons/react/24/outline";
import { handleSubmitFlow } from '../components/utils/gatewayUtils';
import PageContainer from "../components/layout/PageContainer";

const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const userLocale = navigator.language;
  return date.toLocaleString(userLocale, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

interface Notification {
  id: number;
  message: string;
  type: string;
  timestamp: number;
}

const copyToClipboard = async (notification: Notification) => {
  try {
    const textToCopy = `${notification.message}\nDate: ${formatTimestamp(notification.timestamp)}\nType: ${notification.type}`;
    await navigator.clipboard.writeText(textToCopy);
    toast.success('Copied to clipboard');
  } catch (err) {
    console.error('Failed to copy:', err);
    toast.error('Failed to copy to clipboard');
  }
};

interface DeletingNotifications {
  [id: number]: {
    timeoutId: NodeJS.Timeout;
  }
}

const GatewayNotifications = () => {
  const { gatewayId } = useParams({ from: '/gateway/$gatewayId/notifications' });
  const { gateways, activeGateway, setActiveGateway } = useGateways();
  const { publicKey, signMessage } = useWallet();
  const [deletingNotifications, setDeletingNotifications] = useState<DeletingNotifications>({});

  useEffect(() => {
    if (gatewayId && gateways) {
      const gateway = gateways.find(g => g.id === gatewayId);
      if (gateway) {
        setActiveGateway(gateway);
      }
    }
  }, [gatewayId, gateways, setActiveGateway]);

  useEffect(() => {
    if (!activeGateway?.state?.status?.messages) return;
    
    Object.entries(deletingNotifications).forEach(([notificationId, { timeoutId }]) => {
      const notificationStillExists = activeGateway?.state?.status?.messages?.some(
        (notification: Notification) => notification.id === Number(notificationId)
      );
      
      if (!notificationStillExists) {
        clearTimeout(timeoutId);
        setDeletingNotifications(prev => {
          const newState = { ...prev };
          delete newState[Number(notificationId)];
          return newState;
        });
        toast.success('Notification deleted');
      }
    });
  }, [activeGateway?.state?.status?.messages, deletingNotifications]);

  useEffect(() => {
    return () => {
      Object.values(deletingNotifications).forEach(({ timeoutId }) => {
        clearTimeout(timeoutId);
      });
    };
  }, [deletingNotifications]);


  if (!activeGateway) return null;

  const handleDelete = async (id: number) => {
    if (!publicKey || !activeGateway || !signMessage) return;

    const timeoutId = setTimeout(() => {
      if (deletingNotifications[id]) {
        toast.error('Delete notification timed out');
        setDeletingNotifications(prev => {
          const newState = { ...prev };
          delete newState[id];
          return newState;
        });
      }
    }, 30000);

    setDeletingNotifications(prev => ({
      ...prev,
      [id]: { timeoutId }
    }));

    const message = SrcfulMessageProtocolFactory.deleteNotificationMessage({
      walletPublicKey: publicKey.toString(),
      gatewayId: activeGateway.serial,
      notificationId: id
    });

    await handleSubmitFlow(message, signMessage, () => {
      // Success callback - wait for state update
    }, () => {
      // Error callback
      toast.error('Failed to delete notification');
      clearTimeout(timeoutId);
      setDeletingNotifications(prev => {
        const newState = { ...prev };
        delete newState[id];
        return newState;
      });
    });
  };

  return (
    <ContentFramework>
      <PageContainer>
        <GatewayPageHeader 
          gatewayName={activeGateway.name}
        />
        <h1 className="text-4xl font-bold mb-6">Notifications</h1>
        
        <div>
          <div className="space-y-4">
            {activeGateway.state?.status?.messages
              ?.slice()
              .sort((a: Notification, b: Notification) => b.timestamp - a.timestamp)
              .map((notification: Notification) => (
                <div 
                  key={notification.id}
                  className="flex flex-col p-4 rounded-lg bg-gray-800/50 border border-gray-700/50"
                >
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center space-x-2">
                      <span className={`
                        inline-block px-2 py-1 text-xs rounded-full
                        ${notification.type === 'info' ? 'bg-blue-900/50 text-blue-200' : ''}
                        ${notification.type === 'error' ? 'bg-red-900/50 text-red-200' : ''}
                        ${notification.type === 'warning' ? 'bg-yellow-900/50 text-yellow-200' : ''}
                      `}>
                        {notification.type}
                      </span>
                      <p className="text-sm text-gray-400">
                        {formatTimestamp(notification.timestamp)}
                      </p>
                    </div>
                  </div>

                  <div className="space-y-1">
                    <div className="flex justify-between items-start">
                      <p className="text-gray-200">{notification.message}</p>
                      <button
                        onClick={() => copyToClipboard(notification)}
                        className="p-1 hover:bg-white/10 rounded-md transition-colors ml-2 flex-shrink-0"
                        title="Copy message"
                      >
                        <IoCopyOutline className="w-4 h-4" />
                      </button>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-end">
                    <ActionButton
                      onClick={() => handleDelete(notification.id)}
                      variant="destructive"
                      icon={<TrashIcon className="h-4 w-4" />}
                      isLoading={Boolean(deletingNotifications[notification.id])}
                      loadingText="Deleting..."
                    >
                      Delete
                    </ActionButton>
                  </div>
                </div>
              ))
            }
            
            {(!activeGateway.state?.status?.messages || activeGateway.state.status.messages.length === 0) && (
              <div className="text-center text-gray-400 py-8">
                No notifications available
              </div>
            )}
          </div>
        </div>
      </PageContainer>
    </ContentFramework>
  );
};

export default GatewayNotifications; 