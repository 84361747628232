import { FC, useEffect, useState, useRef } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Gateway, useGateways, isHardwareGateway } from "../context/GatewayContext";
import NavItem from "./layout/NavItem";
import { useMatchRoute, useRouter } from "@tanstack/react-router";
import { getUnreadNotificationCount, getNotificationTimestamp, setNotificationTimestamp, NOTIFICATION_VIEW_DELAY } from '../utils/notificationUtils';
import { GatewayIcon, CloudGatewayIcon } from './icons/icons';



interface GatewayListProps {
  onNavigate: () => void;
  expandedGatewayIds: Set<string>;
  setExpandedGatewayIds: (ids: Set<string>) => void;
}

const GatewayList: FC<GatewayListProps> = ({
  onNavigate,
  expandedGatewayIds,
  setExpandedGatewayIds,
}) => {
  const { connected } = useWallet();
  const { gateways, activeGateway } = useGateways();
  const matchRoute = useMatchRoute();
  const router = useRouter();
  const [unreadCounts, setUnreadCounts] = useState<{gatewayId: string, count: number}[]>([]);
  const viewTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const activeGatewayRef = useRef<string | null>(null);
  const currentPathRef = useRef<string | null>(null);

  const getGatewayState = (gateway: Gateway) => {
    // For cloud gateways, return unknown
    
    if (!gateway.state?.timestamp) return 'unknown';
    
    const now = Date.now();
    const diffMinutes = (now - gateway.state.timestamp) / 1000 / 60;
    
    if (diffMinutes < 10) return 'active';
    if (diffMinutes > 60) return 'inactive';
    return 'disabled';
  };

  useEffect(() => {
    if (activeGateway) {
      setExpandedGatewayIds(new Set([activeGateway.id]));
    }
  }, [activeGateway, setExpandedGatewayIds]);

  // Initial calculation of unread counts
  useEffect(() => {
    setUnreadCounts(
      gateways.map(gateway => ({
        gatewayId: gateway.id,
        count: getUnreadNotificationCount(
          gateway.id,
          gateway.state?.status?.messages ?? [],
          getNotificationTimestamp(gateway.id)
        )
      }))
    );
  }, [gateways]);

  // Check if we're on a notifications page and which gateway
  useEffect(() => {
    const currentPath = router.state.location.pathname;

    // If we're already handling this path, skip
    if (currentPath === currentPathRef.current) {
      return;
    }

    // Update current path
    currentPathRef.current = currentPath;

    // Clear any existing timer
    if (viewTimeoutRef.current) {
      // console.log("Clearing existing notification timer");
      clearTimeout(viewTimeoutRef.current);
      viewTimeoutRef.current = null;
      activeGatewayRef.current = null;
    }

    gateways?.forEach(gateway => {
      const notificationsPath = `/gateway/${gateway.id}/notifications`;
      const isNotificationsPage = currentPath === notificationsPath;

      if (isNotificationsPage && !activeGatewayRef.current) {
        // console.log("Starting notification view timer");
        activeGatewayRef.current = gateway.id;
        viewTimeoutRef.current = setTimeout(() => {
          console.log("Marking notifications as read");
          setNotificationTimestamp(gateway.id);
          setUnreadCounts(prevCounts => 
            prevCounts.map(count => 
              count.gatewayId === gateway.id 
                ? { ...count, count: 0 }
                : count
            )
          );
          viewTimeoutRef.current = null;
          activeGatewayRef.current = null;
        }, NOTIFICATION_VIEW_DELAY);
      }
    });

    return () => {
      const previousPath = currentPathRef.current;
      if (!previousPath) return;

      // Check if we're navigating away from a notifications page
      const wasOnNotifications = previousPath.endsWith('/notifications');
      const isStillOnNotifications = currentPath.endsWith('/notifications');

      if (wasOnNotifications && !isStillOnNotifications && viewTimeoutRef.current) {
        // console.log("Cancelled notification view timer - navigated away");
        clearTimeout(viewTimeoutRef.current);
        viewTimeoutRef.current = null;
        activeGatewayRef.current = null;
      }
    };
  }, [router.state.location.pathname, gateways]);


  if (!connected || !gateways || gateways.length === 0) {
    return null;
  }

  const handleExpand = (gateway: Gateway) => {
    const newIds = new Set(expandedGatewayIds);
    
    // Check if any of the gateway's routes are active
    const isOverviewActive = matchRoute({ to: `/gateway/${gateway.id}`, fuzzy: false });
    const isLocationActive = matchRoute({ to: `/gateway/${gateway.id}/location`, fuzzy: false });
    const isDevicesActive = matchRoute({ to: `/gateway/${gateway.id}/devices`, fuzzy: false });
    const isNetworkActive = matchRoute({ to: `/gateway/${gateway.id}/network`, fuzzy: false });
    const isNotificationsActive = matchRoute({ to: `/gateway/${gateway.id}/notifications`, fuzzy: false });
    const hasActiveRoute = isOverviewActive || isLocationActive || isDevicesActive || isNetworkActive || isNotificationsActive;
    
    // If a sub-route is active, don't allow collapsing
    if (hasActiveRoute) {
      newIds.add(gateway.id);
    } else {
      if (newIds.has(gateway.id)) {
        newIds.delete(gateway.id);
      } else {
        newIds.add(gateway.id);
      }
    }
    
    setExpandedGatewayIds(newIds);
  };

  return (
    <div>
      <h2 className="mb-2 px-2 text-xs font-semibold uppercase tracking-wider text-gray-400">
        Your Gateways
      </h2>
      <ul className="space-y-1">
        {gateways.map((gateway) => {
          const unreadCount = unreadCounts.find(u => u.gatewayId === gateway.id)?.count ?? 0;
          
          return (
            <li key={gateway.id}>
              <NavItem
                title={gateway.name}
                isExpanded={expandedGatewayIds.has(gateway.id)}
                onExpand={() => handleExpand(gateway)}
                onClick={() => {}}
                onItemClick={() => {
                  onNavigate();
                }}
                icon={
                  isHardwareGateway(gateway) ? (
                    <GatewayIcon state={getGatewayState(gateway)} className="w-5 h-5 mr-3" />
                  ) : (
                    <CloudGatewayIcon state={getGatewayState(gateway)} className="w-5 h-5 mr-3" />
                  )
                }
                children={
                  isHardwareGateway(gateway) ? [
                    {
                      title: "Overview",
                      path: `/gateway/${gateway.id}`,
                    },
                    {
                      title: "Notifications",
                      path: `/gateway/${gateway.id}/notifications`,
                      badge: unreadCount > 0 ? unreadCount : undefined
                    },
                    {
                      title: "Devices",
                      path: `/gateway/${gateway.id}/devices`,
                    },
                    {
                      title: "Network",
                      path: `/gateway/${gateway.id}/network`,
                    },
                    {
                      title: "Location",
                      path: `/gateway/${gateway.id}/location`,
                    },
                  ] : [
                    {
                      title: "Overview",
                      path: `/gateway/${gateway.id}`,
                    },
                    {
                      title: "Location",
                      path: `/gateway/${gateway.id}/location`,
                    },
                  ]
                }
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default GatewayList;
