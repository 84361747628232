import { FC } from 'react';

// Define the state type that will be used across all icons
export type IconState = 'active' | 'inactive' | 'disabled' | 'unknown' | 'none';

// Common interface for all icons
interface IconProps {
  state?: IconState;
  className?: string;
}

// Common color mapping for all icons
const stateColors = {
  active: 'text-[#00ff84]',   // Bright green
  inactive: 'text-[#ff3700]', // Red
  disabled: 'text-[#ffe500]', // Yellow
  unknown: 'text-[#28ddff]',  // Blue
  none: 'text-[#ffffff]'      // White
} as const;

// Gateway Icon
export const GatewayIcon: FC<IconProps> = ({ state = 'none', className = '' }) => (
  <svg 
    className={`${className} ${stateColors[state]}`} 
    viewBox="0 0 64 65" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M47.9994 10.8323H15.9993C13.0538 10.8323 10.666 13.2201 10.666 16.1656V48.1656C10.666 51.1111 13.0538 53.4989 15.9993 53.4989H47.9994C50.9449 53.4989 53.3327 51.1111 53.3327 48.1656V16.1656C53.3327 13.2201 50.9449 10.8323 47.9994 10.8323Z" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M40 5.49902V10.8324" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M40 53.499V58.8324" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M5.33301 40.1656H10.6663" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M5.33301 24.1656H10.6663" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M53.334 40.1656H58.6673" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M53.334 24.1656H58.6673" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M24 5.49902V10.8324" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M24 53.499V58.8324" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M22.5448 31.6672L33.3187 18.5903C34.0884 17.6561 35.594 18.3846 35.3392 19.5679L33.2294 29.3638C33.0672 30.1172 33.6742 30.8151 34.4428 30.7589L39.8921 30.3601C40.9285 30.2843 41.53 31.509 40.8364 32.2829L28.7424 45.776C27.9183 46.6955 26.4203 45.8543 26.7765 44.672L29.9604 34.1028C30.2092 33.277 29.4979 32.4797 28.6492 32.6331L23.6416 33.5381C22.5832 33.7294 21.8609 32.4973 22.5448 31.6672Z" 
      fill="currentColor"
    />
  </svg>
);

// SolarIcon with dynamic colors
export const SolarIcon: FC<IconProps> = ({ state = 'none', className = '' }) => (
  <svg 
    className={`${className} ${stateColors[state]}`} 
    viewBox="0 0 64 65" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_203_4581)">
      <path 
        d="M15.9151 13.2397C13.435 13.2397 11.3045 14.988 10.8167 17.4193L6.65336 38.2358C6.01098 41.4558 8.46667 44.4644 11.7518 44.4644H29.397V49.6685H24.1928C22.7536 49.6685 21.5908 50.8313 21.5908 52.2706C21.5908 53.7099 22.7536 54.8726 24.1928 54.8726H39.8052C41.2444 54.8726 42.4072 53.7099 42.4072 52.2706C42.4072 50.8313 41.2444 49.6685 39.8052 49.6685H34.6011V44.4644H52.2463C55.5314 44.4644 57.9952 41.4639 57.3528 38.2358L53.1895 17.4193C52.6935 14.988 50.5631 13.2397 48.083 13.2397H15.9151ZM27.1933 18.4439H36.8047L37.6504 26.9005H26.3477L27.1933 18.4439ZM22.4283 26.9005H14.2319L15.9151 18.4439H23.2659L22.4283 26.9005ZM13.4431 30.8036H22.0299L21.1924 39.2603H11.7518L13.4431 30.8036ZM25.9574 30.8036H38.0407L38.8863 39.2603H25.1117L25.9574 30.8036ZM41.96 30.8036H50.5468L52.2463 39.2603H42.8138L41.96 30.8036ZM49.7662 26.9005H41.5697L40.724 18.4439H48.0748L50.6281 17.9316L48.083 18.4439L49.7743 26.9005H49.7662Z" 
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_203_4581">
        <rect width="52.0411" height="41.6329" fill="white" transform="translate(5.97852 13.2397)"/>
      </clipPath>
    </defs>
  </svg>
);

// MeterIcon with dynamic colors
export const MeterIcon: FC<IconProps> = ({ state = 'none', className = '' }) => (
  <svg 
    className={`${className} ${stateColors[state]}`} 
    viewBox="0 0 64 65" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M41.6008 7.3657C35.8973 5.14992 29.6053 4.96294 23.7803 6.83615C17.9553 8.70936 12.9517 12.5288 9.60882 17.6537C6.26596 22.7786 4.78722 28.8972 5.42067 34.9831C6.05413 41.0691 8.76124 46.752 13.0879 51.0786C17.4145 55.4053 23.0974 58.1124 29.1834 58.7458C35.2693 59.3793 41.3879 57.9005 46.5128 54.5577C51.6377 51.2148 55.4572 46.2112 57.3304 40.3862C59.2036 34.5612 59.0166 28.2692 56.8008 22.5657" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M31.9993 37.4989C34.9449 37.4989 37.3327 35.1111 37.3327 32.1656C37.3327 29.2201 34.9449 26.8323 31.9993 26.8323C29.0538 26.8323 26.666 29.2201 26.666 32.1656C26.666 35.1111 29.0538 37.4989 31.9993 37.4989Z" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
    <path 
      d="M35.7344 28.4324L50.6677 13.499" 
      stroke="currentColor" 
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

// Cloud Gateway Icon
export const CloudGatewayIcon: FC<IconProps> = ({ state = 'none', className = '' }) => (
  <svg 
    className={`${className} ${stateColors[state]}`} 
    viewBox="0 0 64 65" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path 
      d="M22.1806 45.9585L32.9545 32.8817C33.7241 31.9475 35.2298 32.676 34.9749 33.8593L32.8652 43.6552C32.7029 44.4086 33.31 45.1065 34.0785 45.0503L39.5278 44.6515C40.5643 44.5757 41.1658 45.8004 40.4721 46.5743L28.3781 60.0674C27.554 60.9868 26.0561 60.1456 26.4122 58.9634L29.5962 48.3942C29.845 47.5683 29.1337 46.7711 28.2849 46.9245L23.2774 47.8295C22.219 48.0207 21.4967 46.7886 22.1806 45.9585Z"
      fill="currentColor"
    />
    <path 
      d="M11.2473 42.8757C9.2661 40.8514 7.7715 38.4029 6.87675 35.7155C5.98199 33.0281 5.71054 30.1723 6.08295 27.3645C6.45536 24.5566 7.46187 21.8703 9.02624 19.5091C10.5906 17.1478 12.6718 15.1736 15.1122 13.7358C17.5526 12.298 20.2882 11.4345 23.1118 11.2105C25.9353 10.9866 28.7729 11.4082 31.4094 12.4433C34.0459 13.4784 36.4123 15.1 38.3293 17.1851C40.2463 19.2702 41.6636 21.7643 42.474 24.4783H47.2473C49.822 24.478 52.3285 25.3058 54.3966 26.8395C56.4647 28.3731 57.9847 30.5313 58.732 32.9951C59.4794 35.459 59.4145 38.0979 58.5469 40.522C57.6793 42.9461 56.055 45.0269 53.914 46.457"
      stroke="currentColor"
      strokeWidth="5.33333" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
); 