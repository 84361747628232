import React from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useGateways } from '../context/GatewayContext';
import { GatewaySigningStep } from './GatewaySigningStep';
import { RouterProvider } from "@tanstack/react-router";
import { router } from "../router";
import PopupModule from "./layout/PopupModule";

export const AppContent = () => {
  const { connected } = useWallet();
  const { gateways, isGatewaysSigned, isLoading, suppressSigningStep } = useGateways();

  // Don't render anything while we're loading the gateways
  if (connected && isLoading) {
    return null;
  }

  // Show signing step if needed, but respect suppressSigningStep flag
  if (connected && gateways?.length > 0 && !isGatewaysSigned && !suppressSigningStep) {
    return <GatewaySigningStep />;
  }

  // Show main app content
  return (
    <div className="flex flex-col md:h-screen">
      <PopupModule />
      <RouterProvider router={router} />
    </div>
  );
}; 