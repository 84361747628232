import { FC, useState, useEffect } from 'react';
import gwBluetooth from '../../assets/gwschematics/gw-bluetooth.png';
import gwEthernet from '../../assets/gwschematics/gw-ethernet.png';
import gwPowerSupply from '../../assets/gwschematics/gw-power-supply.png';

const IntroStep: FC = () => {
  const images = [gwPowerSupply, gwEthernet, gwBluetooth];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setIsTransitioning(false);
      }, 500); // Half of the transition duration
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(timer);
  }, [images.length]);

  return (
    <div className="space-y-1">
      <h2 className="text-2xl font-bold text-white">Onboard your Gateway</h2>
      <div className="relative h-64 w-full overflow-hidden">
        <img
          src={images[currentImageIndex]}
          alt={`Gateway setup step ${currentImageIndex + 1}`}
          className={`absolute inset-0 h-full w-full object-contain transition-opacity duration-1000 ${
            isTransitioning ? 'opacity-0' : 'opacity-100'
          }`}
        />
      </div>
      <div className="space-y-1 text-gray-300">
        <p>Plug in the gateway to power and then click 'Next' to begin the setup process.</p>
      </div>
    </div>
  );
};

export default IntroStep; 